import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "@context/AppContext";
import { Icon } from "@common";
import { CreateTeamModal } from "../_modals/CreateTeamModal/CreateTeamModal";
import CreatePlayerModal from "../_modals/CreatePlayerModal/CreatePlayerModal";
import CreateOrgModal from "../_modals/CreateOrgModal/CreateOrgModal";
import CreateCoachModal from "../_modals/CreateCoachModal/CreateCoachModal";
import useTranslated from "../../helpers/translationHelper";

const Welcome = () => {
  const { user } = useContext(AppContext);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [showCoachModal, setShowCoachModal] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);

  return (
    <div className="registration-welcome">
      <CreatePlayerModal open={showPlayerModal} onClose={() => setShowPlayerModal(false)} />
      <CreateOrgModal open={showOrgModal} onClose={() => setShowOrgModal(false)} afterCreate={() => false} />
      <CreateCoachModal open={showCoachModal} onClose={() => setShowCoachModal(false)} />
      <CreateTeamModal open={showTeamModal} onClose={() => setShowTeamModal(false)}/>

      <div className="modal modal-inline on-page">
        <div className="modal-header">
          <h2>{useTranslated("id_welcome_0")/* Welcome to Leagues */}, {user.state.firstName}!</h2>
          <h3>{useTranslated("id_welcome_1")/* Choose what to do with your new profile. All of these actions can be done later as well. */}</h3>
        </div>

        <div className="modal-body">
          <div className="text-14 bold uppercase pb-2 left">{useTranslated("id_welcome_2")/* Choose what to create */}</div>
          {/* Player */}
          <div className="account-type-item" onClick={() => setShowPlayerModal(true)}>
            <Icon name="game-welcome" />
            <div className="account-type-item-text">
              <div className="text-12 bold uppercase left">{useTranslated("id_welcome_3")/* Player */}</div>

              <div className="text-10 faded left">
                {useTranslated("id_welcome_4")/* Players can participate in leagues and tournaments, and can look for teams using the Finder. */}
              </div>
            </div>
          </div>
          {/* team popup */}
          <div className="account-type-item" onClick={() => setShowTeamModal(true)}>
            <Icon name="teams" iconClass="white-color-teams" color={'#fff'}/>
            <div className="account-type-item-text">
              <div className="text-12 bold uppercase left">Team</div>

              <div className="text-10 faded left">
              Teams can add players in various roles and compete in leagues and tournaments for prizes.
              </div>
            </div>
          </div>
          {/* Organisation */}
          <div className="account-type-item" onClick={() => setShowOrgModal(true)}>
            <Icon name="org-welcome" />
            <div className="account-type-item-text">
              <div className="text-12 bold uppercase left">{useTranslated("id_welcome_5")/* Organisation */}</div>

              <div className="text-10 faded left">
              {useTranslated("id_welcome_6")/* This is for owners of organisations. To structure teams you will need to have an organisation associated. */}
              </div>
            </div>
          </div>
          {/* Coach */}
          <div className="account-type-item" onClick={() => setShowCoachModal(true)}>
            <Icon name="coach-welcome" />
            <div className="account-type-item-text">
              <div className="text-12 bold uppercase left">{useTranslated("id_welcome_7")/* Coach */}</div>

              <div className="text-10 faded left">
                {useTranslated("id_welcome_8")/* Coaches can join and look for teams just like players, but different information is shown on the profile. */}
              </div>
            </div>
          </div>
          <div className="text-10 faded" style={{ marginTop: "18px" }}>
            {useTranslated("id_welcome_9")/* You can always skip this step and choose what to do later when needed. */}
          </div>

          <div className="button-container">
            <Link to="/" className="text-12 primary-link">
              {useTranslated("id_welcome_10")/* Remind me later */}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
