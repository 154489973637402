import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { FaAngleDown } from "react-icons/fa"

const SelectorOption = ({ option, value, badge, selected, handleOptionChange }) => {
    console.log(option)
    return (
      <OptionLabel>
        <CheckboxWrapper>
            <Checkbox
            type="checkbox"
            value={value}
            checked={selected}
            onChange={() => handleOptionChange(option)}
            />
            <Checkmark />
        </CheckboxWrapper>
        { badge && (
            <RegionCode className="region-code">
                <span>{badge}</span>
            </RegionCode>
        )}
        <LabelSpan>
            {value && value.charAt(0).toUpperCase() + value.slice(1)}
        </LabelSpan>
      </OptionLabel>
    );
  };

const LabelSpan = styled.span`
    margin-left: 10px;
`

const Selector = ({multiple = false, required = false, customButton, options, selectorLabelHandler, selectedOptions, setSelectedOptions, badge = false, value, label}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdown = useRef()
    // minimum choose 1 region
    // set to EUW by default
    // regions are game specific

    const toggleDropdown = (e) => {
        e.preventDefault()
        setIsOpen(!isOpen)
    };

   
    const handleOptionChange = (optionValue) => {
        if (selectedOptions.includes(optionValue)) {
            if(selectedOptions.length <= 1 && required) return
            setSelectedOptions((prevSelectedOptions) =>
                prevSelectedOptions.filter((value) => value !== optionValue)
            );
        } else {
          setSelectedOptions((prevSelectedOptions) =>
            [...prevSelectedOptions, optionValue]
          );
        }
      };

    const isSelected = (array, obj, comparatorFn) => {
        console.log('isSelected', array, obj, comparatorFn)
        if(comparatorFn) {
            return array.some((element) => comparatorFn(element, obj))
        } else {
            return array.includes(obj)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])

    const handleOutsideClick = (e) => {
        if(dropdown.current && !dropdown.current.contains(e.target)){
            setIsOpen(false)
        }
    }

    return (
        <SelectorWrapper customButton={customButton} ref={dropdown}>
            {!customButton ? (
                <Button onClick={toggleDropdown}>
                    <SpanSelectedLanguages>
                        { selectedOptions && selectorLabelHandler() }
                    </SpanSelectedLanguages>
                    <SpanRight>
                        <StyledCaret className={isOpen ? 'dropdown-open' : ''} />
                    </SpanRight>
                </Button>
            ): React.cloneElement(customButton, { onClick: toggleDropdown })
            }
            
            {isOpen && (
            <OptionsDropdown >
                {options && options.map((option, i) => (
                    <SelectorOption
                        option={option}
                        key={value ? option[value]: i}
                        badge={(badge && value) && option[value]}
                        value={typeof option === 'object' ? option[label] : option}
                        selected={isSelected(selectedOptions, option)}
                        handleOptionChange={handleOptionChange}
                    />
                ))}
            </OptionsDropdown>
            )}
        </SelectorWrapper>
    )
}

const SpanSelectedLanguages = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-right: 10px;
`
const SpanRight = styled.div`
    display: flex;
`

const SelectorWrapper = styled.div`
    display: inline-flex;
    position: relative;
    /* margin: 0.5em; */

    ${props => props.customButton && 
        css`
            height: 100%;
        `}
`

const Button = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    /* padding: 5px 10px; */
    padding-left: 15px;
    cursor: pointer;
    min-width: 50px;
    color: var(--primary);
    font-weight: bold;
    font-size: 0.7em;
    letter-spacing: 0.1em;
    /* margin: 5px; */

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 20%;
        left: 8px;
        width: 100%;
        height: 60%;
        background-color: var(--primary);
        opacity: 0.1;
        z-index: 0;
        border-radius: 2px;
      }
`



const Checkmark = styled.span`
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-border);
    border-radius: 2px;
    transition: background-color 0.3s ease-in-out;
    
    &:after {
        content: "";
        position: absolute;
        display: none;
    }
`

const OptionLabel = styled.label`
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    transition: display 0.3s ease-in-out;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &:hover input ~ ${Checkmark} {
        background-color: #ccc;
    }

    input:checked ~ ${Checkmark} {
        background-color: var(--primary);
    }

    input:checked ~ ${Checkmark}:after {
        display: block;
    }
    
    ${Checkmark}:after {
        left: 6px;
        top: 2px;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        scale: 0.8;
    }

`

const OptionsDropdown = styled.div`
    position: absolute;
    top: 130%;
    left: 0;
    min-width: 220px;
    white-space: nowrap;
    padding: 12px;
    background-color: var(--color-bg-header);
    border: 1px solid var(--color-border);
    border-radius: 2px
    color: var(--primary);
    z-index: 11;

    ${OptionLabel}:last-child {
        margin-bottom: 0;
    }
`

const Checkbox = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #555;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  position: relative;
  margin-right: 10px;

  
`





const RegionCode = styled.span`
    display: flex;
    justify-content: center;
    position: relative;
    padding: 5px;
    color: var(--primary);
    font-weight: bold;
    font-size: 0.7em;
    letter-spacing: 0.1em;
    min-width: 50px;
    margin-left: 10px;
    
    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--primary);
        opacity: 0.1;
        z-index: 0;
        border-radius: 2px;
      }
`

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 20px;
`

const StyledCaret = styled(FaAngleDown)`
    font-size: 1.5em;
    transition all .3s ease-in-out;
    
    &.dropdown-open {
        transform: rotate(180deg);
    }
`


export default Selector