import React, { useState } from "react";
import { Icon, Loading } from "@common";
import Axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { RiCloseLine } from "react-icons/ri";
import { useHistory } from "react-router";
import { checkIfTimeInBody } from "../../../../../utils/additional-helpers";
import Tippy from "@tippyjs/react";
const NotificationItem = ({ _id, img, title, body, createdAt, read, actions, expired, new: recentlyAdded, afterActionComplete }) => {
  const [actionProcessing, setActionProcessing] = useState(false);
  const history = useHistory();

  const onToggleRead = async (successMsg = false) => {
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/notifications/${_id}/markAs`, { read: !read });
      afterActionComplete({ _id, title, body, createdAt }, successMsg);
    } catch (e) {
      toast.error("Couldn't change notification status");
    }
  };

  const onClickAction = async (name, url, type) => {
    setActionProcessing(name);

    try {
      if (name === "view request") {
        await onToggleRead(true);
        history.push(url);
      } else {
        if (!type) {
          await Axios.post(`${process.env.REACT_APP_CORE_API}/api${url}`);
        } else if (type == "web") {
          window.open(url, "_blank");
        }
        await onToggleRead(true);
      }
    } catch (e) {
      toast.error("A problem occurred. Please try again later.");
    }

    setActionProcessing(false);
  };

  return (
    <div className="notification-item">
      <div className="notification-item-image" style={{ backgroundImage: `url(${img})` }}>
        {!img && <Icon name="bell" />}
        {recentlyAdded && <div className="new-notif-dot" />}
      </div>
      <div className="notification-item-inner">
        <div className="notification-item-title">
          <h3>{title}</h3>
          <button className="button-link" onClick={() => onToggleRead(false)}>
            <RiCloseLine />
          </button>
        </div>

        <p>{checkIfTimeInBody(body)}.</p>

        <small>{moment(createdAt).fromNow()}</small>

        <div className="notification-actions">
          {
            actions &&
            actions?.map(({ name, url, type }, i) => (
              !expired ? <button
                key={i}
                className={`button small mr-2 ${i === 0 ? "primary" : "bordered"}`}
                disabled={!!actionProcessing}
                onClick={() => onClickAction(name, url, type)}
              >
                {actionProcessing === name ? <Loading width={15} height={15} /> : name}
              </button> : 
               <Tippy content={`This ${title.includes("New Team Invite") ? "invitation" : "notification"} has expired`}>
               <span>
                 <button
                   key={i}
                   className={`button small mr-2`} style={{backgroundColor: 'lightgray'}}
                   disabled={true}
                 >
                   {name}
                 </button>
               </span>
             </Tippy>
            ))}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
