import React, { useState } from "react";
import { TeamMemberListItem, List } from "@common";
import AddTeamMemberModal from "@modals/AddTeamMemberModal/AddTeamMemberModal";
import Axios from "axios";
import { toast } from "react-toastify";

const AllMembers = ({ team, isOwner, isManager }) => {
  const [pendingInvites, setPendingInvites] = useState(isOwner ? team.pendingInvites : []);
  const [members, setMembers] = useState(team.members || []);
  const [showAddMember, setShowAddMember] = useState(false);

  const availableNewRoles = team.game.configuration.playerTypes
    .map(({ name }) => "Player:" + name)
    .filter((playerType) => playerType.toLowerCase().includes("sub") || !pendingInvites.find(({ role }) => role === playerType))
    .filter((playerType) => playerType.toLowerCase().includes("sub") || !members.find(({ role }) => role === playerType));

  const getPlayerPositionData = (role = "") => {
    const roleSplit = role.split(":")[1];

    if (roleSplit) return (team.game || {}).configuration.playerTypes.find(({ name }) => name === roleSplit);
    return false;
  };

  const afterInviteMembers = (invites) => {
    setPendingInvites(invites.map((invite) => ({ ...invite, type: "invite" })));
  };

  const onChangeRole = async (id, newRole) => {
    try {
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}/members/${id}`, { newRole });
      setMembers(members.map((member) => (member._id === id ? { ...member, role: newRole } : member)));
      return toast.success("Successfully changed role!");
    } catch (error) {
      return toast.error("There was a problem changing role");
    }
  };

  const onDeleteMember = async (id) => {
    if (window.confirm("Are you sure?")) {
      try {
        await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}/members/${id}`);
        toast.success("Successfully deleted member");
        setMembers((members) => members.filter(({ _id }) => _id !== id));
      } catch (e) {
        toast.error("Could not delete member");
      }
    }
  };

  const onDeleteInvite = async (id) => {
    if (window.confirm("Are you sure?")) {
      try {
        await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/teams/invites/${id}`);
        toast.success("Successfully deleted invite");
        setPendingInvites((invites) => invites.filter(({ _id }) => _id !== id));
      } catch (e) {
        toast.error("Could not delete invite");
      }
    }
  };

  const playerTypes = (team.game || {}).configuration.playerTypes.map(({ name }) => `Player:${name}`);
  const memberSections = [
    { name: "Pending Invites", members: pendingInvites.map((invite) => ({ ...invite, type: "invite" })) },
    {
      name: "Players",
      members: members.filter(({ role }) => role && playerTypes.includes(role) && !role.toLowerCase().includes("sub")),
    },
    { name: "Coaches", members: members.filter(({ role }) => role === "Coach") },
    { name: "Others", members: members.filter(({ role }) => role === "") },
    {
      name: "Staff",
      members:
        [
          ...members.filter(({ role }) => ["Manager", "Owner"].includes(role)),
          ...team.organisation.owners.map((owner) => ({ ...owner, role: `${owner?.role || "Owner"} (Organisation)` })),
        ],
    },
  ];
  let ordering = {}, // map for efficient lookup of sortIndex
    sortOrder = ["Player:TOP", "Player:JUNGLE", "Player:MID", "Player:BOT", "Player:SUPPORT"];
  for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;

  return (
    <div>
      {isOwner && (
        <button className="button wide-primary" onClick={() => setShowAddMember(true)}>
          Add player, coach or staff
        </button>
      )}

      {memberSections.map(
        ({ name, members = [] }, i) =>
          !!members.length && (
            <div className="row mb-4" key={i}>
              <div className="col-3">
                <span className="text-faded uppercase bold">{name}</span>
              </div>
              <div className="col-9">
                <List>
                  {members
                    .sort((a, b) => ordering[a.role] - ordering[b.role])
                    .map((singleMember) => {
                      if (singleMember && singleMember._id) {
                        const { _id, user, player, coach, role, type } = singleMember;
                        if (type === "invite") {
                          return (
                            <TeamMemberListItem
                              key={_id}
                              img={user ? user.avatarImage : player ? player.avatarImage : false}
                              title={
                                user
                                  ? `${user.firstName}${user.nickname ? ` "${user.nickname}" ` : " "}${user.lastName}`
                                  : player
                                    ? player.name
                                    : coach
                                      ? coach.name
                                      : false
                              }
                              subtitle={
                                player
                                  ? `${player.user.firstName}${player.user.nickname ? ` "${player.user.nickname}" ` : " "}${player.user.lastName
                                  }`
                                  : coach
                                    ? `${coach.user.firstName}${coach.user.nickname ? ` "${coach.user.nickname}" ` : " "}${coach.user.lastName
                                    }`
                                    : false
                              }
                              position={player ? getPlayerPositionData(role) : false}
                              role={!player ? role : false}
                              nationality={
                                player ? player.user.nationality : coach ? coach.user.nationality : user ? user.nationality : false
                              }
                              pending={true}
                              actions={[
                                <button
                                  onClick={() => onDeleteInvite(_id)}
                                  className="text-14 primary-link color-danger"
                                  style={{ padding: 0 }}
                                >
                                  Delete
                                </button>,
                              ]}
                            />
                          );
                        } else {
                          return (
                            <TeamMemberListItem
                              key={_id}
                              img={user ? user.avatarImage : player ? player.avatarImage : false}
                              title={
                                user
                                  ? `${user.firstName}${user.nickname ? ` "${user.nickname}" ` : " "}${user.lastName}`
                                  : player
                                    ? player.name
                                    : coach
                                      ? coach.name
                                      : false
                              }
                              subtitle={
                                player
                                  ? `${player.user.firstName}${player.user.nickname ? ` "${player.user.nickname}" ` : " "}${player.user.lastName
                                  }`
                                  : coach
                                    ? `${coach.user.firstName}${coach.user.nickname ? ` "${coach.user.nickname}" ` : " "}${coach.user.lastName
                                    }`
                                    : false
                              }
                              position={player ? getPlayerPositionData(role) : false}
                              role={!player ? role : false}
                              nationality={player ? player.nationality : coach ? coach.user.nationality : user ? user.nationality : false}
                              editable={isOwner || isManager}
                              availableNewRoles={availableNewRoles}
                              onChangeRole={(newRole) => onChangeRole(_id, newRole)}
                              actions={[
                                <a
                                  href={
                                    player
                                      ? "/game-profile/" + player._id
                                      : coach
                                        ? "/coaches/" + coach._id
                                        : user
                                          ? "/players/" + user._id
                                          : false
                                  }
                                  className="text-14 primary-link"
                                >
                                  View profile
                                </a>,
                                isOwner && isManager && role !== "Owner (Organisation)" && role !== "Manager (Organisation)" && (
                                  <button
                                    onClick={() => onDeleteMember(_id)}
                                    className="text-14 primary-link color-danger"
                                    style={{ padding: 0 }}
                                  >
                                    Delete
                                  </button>
                                ),
                              ]}
                            />
                          );
                        }
                      }
                    })}
                </List>
              </div>
            </div>
          )
      )}

      <AddTeamMemberModal
        open={showAddMember}
        onClose={() => setShowAddMember(false)}
        team={team}
        members={members}
        pendingInvites={pendingInvites}
        afterAdd={afterInviteMembers}
        isManager={isManager}
        isOwner={isOwner}
      />
    </div>
  );
};

export default AllMembers;
