import React, { useEffect, useState } from "react";
import { SimpleTabs, GameOverviewBox, Loading } from "@common";
import Axios from "axios";
import GamesOverviewTeamMembersList from "./GamesOverviewTeamMembersList/GamesOverviewTeamMembersList";
import MissingData from "../MissingData/MissingData";
import { defaultTeamLogo } from "../../../../utils/constants";
import useTranslated from "../../../../helpers/translationHelper";

const Games = ({ match, tournamentCodesButton }) => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [championsData, setChampionsData] = useState();
  const mapTab = useTranslated('id_match_page_(games)_1')
  const victoryTag = useTranslated('id_match_page_(games)_2')
  const defeatTag = useTranslated('id_match_page_(games)_3')
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_LOL_API}/api/riot/champions`).then(({ data }) => {
      setChampionsData(data);
      setLoading(false);
    });
  }, []);

  const pad2 = (n) => {
    return n < 10 ? "0" + n : n;
  };

  const lanes = match.league && match.league.game.configuration.playerTypes;
  if (!lanes) {
    return null;
  }

  let pages = match?.lolData?.mapData?.map((_, i) => `${mapTab} ${i + 1}`) || [];

  return loading ? (
    <div className="text-center">
      <Loading />
    </div>
  ) : !match?.lolData?.mapData?.length ? (
    <MissingData
      text={
        match.scoreReason && (
          <span>
            Reason: <em>{match.scoreReason}</em>
          </span>
        )
      }
    />
  ) : (
    <div className="your-matches-games">
      
        <SimpleTabs  pages={pages} page={page} setPage={setPage} />
        {tournamentCodesButton}
        {/* <a href="/#" className="text-14 primary-link">
          ADVANCED VIEW
        </a> */}

      {match.lolData.mapData.map((map, i) => {
        const evenMap = i % 2 === 0;
        const switchSides = match.switchSides;
        const team1 = switchSides ? (evenMap ? match.t1 : match.t2) : match.t1;
        const team2 = switchSides ? (evenMap ? match.t2 : match.t1) : match.t2;

        if (i === page) {
          return (
            <div>
              <div className="row smaller-gutter-row">
                <div className="col-5 center games-header">
                  <img
                    src={team1?.logo || defaultTeamLogo}
                    alt={team1?.name || ""}
                    className={team1?.logoCropped || team1?.logoCropped === undefined ? "border-radius-100" : ""}
                  />
                  <span className="text-14 bold uppercase">{team1?.name || "[DELETED]"}</span>
                  {map.teams[0].win === "Win" ? <span className="pill success">VICTORY</span> : <span className="pill danger">DEFEAT</span>}
                </div>
                <div className="col-2 center games-header">
                  {map?.gameDuration && (
                    <span className="pill">{`${Math.round(map?.gameDuration / 60)}:${pad2(map?.gameDuration % 60)}`}</span>
                  )}
                </div>
                <div className="col-5 center games-header">
                  <img
                    src={team2?.logo || defaultTeamLogo}
                    alt={team2?.name || ""}
                    className={team2?.logoCropped || team2?.logoCropped === undefined ? "border-radius-100" : ""}
                  />
                  <span className="text-14 bold uppercase">{team2?.name || "[DELETED]"}</span>
                  {map.teams[1].win === "Win" ? <span className="pill success">{victoryTag}</span> : <span className="pill danger">{defeatTag}</span>}
                </div>
              </div>

              <div className="row smaller-gutter-row pt-3 pb-3">
                <div className="col-12">
                  <GameOverviewBox map={map} champions={championsData} />
                </div>
              </div>

              <div className="row smaller-gutter-row">
                <div className="col-6">
                  <GamesOverviewTeamMembersList lanes={lanes} teamId={100} map={map} champions={championsData} />
                </div>
                <div className="col-6">
                  <GamesOverviewTeamMembersList lanes={lanes} teamId={200} map={map} champions={championsData} />
                </div>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default Games;
