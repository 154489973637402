export const countries = [
    {
        "alpha2Code": "AF",
        "demonym": "Afghan"
    },
    {
        "alpha2Code": "AX",
        "demonym": "Ålandish"
    },
    {
        "alpha2Code": "AL",
        "demonym": "Albanian"
    },
    {
        "alpha2Code": "DZ",
        "demonym": "Algerian"
    },
    {
        "alpha2Code": "AS",
        "demonym": "American Samoan"
    },
    {
        "alpha2Code": "AD",
        "demonym": "Andorran"
    },
    {
        "alpha2Code": "AO",
        "demonym": "Angolan"
    },
    {
        "alpha2Code": "AI",
        "demonym": "Anguillian"
    },
    {
        "alpha2Code": "AQ",
        "demonym": ""
    },
    {
        "alpha2Code": "AG",
        "demonym": "Antiguan, Barbudan"
    },
    {
        "alpha2Code": "AR",
        "demonym": "Argentinean"
    },
    {
        "alpha2Code": "AM",
        "demonym": "Armenian"
    },
    {
        "alpha2Code": "AW",
        "demonym": "Aruban"
    },
    {
        "alpha2Code": "AU",
        "demonym": "Australian"
    },
    {
        "alpha2Code": "AT",
        "demonym": "Austrian"
    },
    {
        "alpha2Code": "AZ",
        "demonym": "Azerbaijani"
    },
    {
        "alpha2Code": "BS",
        "demonym": "Bahamian"
    },
    {
        "alpha2Code": "BH",
        "demonym": "Bahraini"
    },
    {
        "alpha2Code": "BD",
        "demonym": "Bangladeshi"
    },
    {
        "alpha2Code": "BB",
        "demonym": "Barbadian"
    },
    {
        "alpha2Code": "BY",
        "demonym": "Belarusian"
    },
    {
        "alpha2Code": "BE",
        "demonym": "Belgian"
    },
    {
        "alpha2Code": "BZ",
        "demonym": "Belizean"
    },
    {
        "alpha2Code": "BJ",
        "demonym": "Beninese"
    },
    {
        "alpha2Code": "BM",
        "demonym": "Bermudian"
    },
    {
        "alpha2Code": "BT",
        "demonym": "Bhutanese"
    },
    {
        "alpha2Code": "BO",
        "demonym": "Bolivian"
    },
    {
        "alpha2Code": "NL",
        "demonym": "Dutch"
    },
    {
        "alpha2Code": "BQ",
        "demonym": "Bonaire, Saint Eustatius and Saba"
    },
    {
        "alpha2Code": "BA",
        "demonym": "Bosnian, Herzegovinian"
    },
    {
        "alpha2Code": "BW",
        "demonym": "Motswana"
    },
    {
        "alpha2Code": "BV",
        "demonym": ""
    },
    {
        "alpha2Code": "BR",
        "demonym": "Brazilian"
    },
    {
        "alpha2Code": "IO",
        "demonym": "Indian"
    },
    {
        "alpha2Code": "UM",
        "demonym": "American"
    },
    {
        "alpha2Code": "VG",
        "demonym": "Virgin Islander"
    },
    {
        "alpha2Code": "VI",
        "demonym": "Virgin Islander"
    },
    {
        "alpha2Code": "BN",
        "demonym": "Bruneian"
    },
    {
        "alpha2Code": "BG",
        "demonym": "Bulgarian"
    },
    {
        "alpha2Code": "BF",
        "demonym": "Burkinabe"
    },
    {
        "alpha2Code": "BI",
        "demonym": "Burundian"
    },
    {
        "alpha2Code": "KH",
        "demonym": "Cambodian"
    },
    {
        "alpha2Code": "CM",
        "demonym": "Cameroonian"
    },
    {
        "alpha2Code": "CA",
        "demonym": "Canadian"
    },
    {
        "alpha2Code": "CV",
        "demonym": "Cape Verdian"
    },
    {
        "alpha2Code": "KY",
        "demonym": "Caymanian"
    },
    {
        "alpha2Code": "CF",
        "demonym": "Central African"
    },
    {
        "alpha2Code": "TD",
        "demonym": "Chadian"
    },
    {
        "alpha2Code": "CL",
        "demonym": "Chilean"
    },
    {
        "alpha2Code": "CN",
        "demonym": "Chinese"
    },
    {
        "alpha2Code": "CX",
        "demonym": "Christmas Island"
    },
    {
        "alpha2Code": "CC",
        "demonym": "Cocos Islander"
    },
    {
        "alpha2Code": "CO",
        "demonym": "Colombian"
    },
    {
        "alpha2Code": "KM",
        "demonym": "Comoran"
    },
    {
        "alpha2Code": "CG",
        "demonym": "Congolese"
    },
    {
        "alpha2Code": "CD",
        "demonym": "Congolese"
    },
    {
        "alpha2Code": "CK",
        "demonym": "Cook Islander"
    },
    {
        "alpha2Code": "CR",
        "demonym": "Costa Rican"
    },
    {
        "alpha2Code": "HR",
        "demonym": "Croatian"
    },
    {
        "alpha2Code": "CU",
        "demonym": "Cuban"
    },
    {
        "alpha2Code": "CW",
        "demonym": "Curacao"
    },
    {
        "alpha2Code": "CY",
        "demonym": "Cypriot"
    },
    {
        "alpha2Code": "CZ",
        "demonym": "Czech"
    },
    {
        "alpha2Code": "DK",
        "demonym": "Danish"
    },
    {
        "alpha2Code": "DJ",
        "demonym": "Djibouti"
    },
    {
        "alpha2Code": "DM",
        "demonym": "Dominican"
    },
    {
        "alpha2Code": "DO",
        "demonym": "Dominican"
    },
    {
        "alpha2Code": "EC",
        "demonym": "Ecuadorean"
    },
    {
        "alpha2Code": "EG",
        "demonym": "Egyptian"
    },
    {
        "alpha2Code": "SV",
        "demonym": "Salvadoran"
    },
    {
        "alpha2Code": "GQ",
        "demonym": "Equatorial Guinean"
    },
    {
        "alpha2Code": "ER",
        "demonym": "Eritrean"
    },
    {
        "alpha2Code": "EE",
        "demonym": "Estonian"
    },
    {
        "alpha2Code": "ET",
        "demonym": "Ethiopian"
    },
    {
        "alpha2Code": "FK",
        "demonym": "Falkland Islander"
    },
    {
        "alpha2Code": "FO",
        "demonym": "Faroese"
    },
    {
        "alpha2Code": "FJ",
        "demonym": "Fijian"
    },
    {
        "alpha2Code": "FI",
        "demonym": "Finnish"
    },
    {
        "alpha2Code": "FR",
        "demonym": "French"
    },
    {
        "alpha2Code": "GF",
        "demonym": ""
    },
    {
        "alpha2Code": "PF",
        "demonym": "French Polynesian"
    },
    {
        "alpha2Code": "TF",
        "demonym": "French"
    },
    {
        "alpha2Code": "GA",
        "demonym": "Gabonese"
    },
    {
        "alpha2Code": "GM",
        "demonym": "Gambian"
    },
    {
        "alpha2Code": "GE",
        "demonym": "Georgian"
    },
    {
        "alpha2Code": "DE",
        "demonym": "German"
    },
    {
        "alpha2Code": "GH",
        "demonym": "Ghanaian"
    },
    {
        "alpha2Code": "GI",
        "demonym": "Gibraltar"
    },
    {
        "alpha2Code": "GR",
        "demonym": "Greek"
    },
    {
        "alpha2Code": "GL",
        "demonym": "Greenlandic"
    },
    {
        "alpha2Code": "GD",
        "demonym": "Grenadian"
    },
    {
        "alpha2Code": "GP",
        "demonym": "Guadeloupian"
    },
    {
        "alpha2Code": "GU",
        "demonym": "Guamanian"
    },
    {
        "alpha2Code": "GT",
        "demonym": "Guatemalan"
    },
    {
        "alpha2Code": "GG",
        "demonym": "Channel Islander"
    },
    {
        "alpha2Code": "GN",
        "demonym": "Guinean"
    },
    {
        "alpha2Code": "GW",
        "demonym": "Guinea-Bissauan"
    },
    {
        "alpha2Code": "SCT",
        "demonym": "Scotland"
    },
    {
        "alpha2Code": "WAL",
        "demonym": "Wales"
    },
    {
        "alpha2Code": "GY",
        "demonym": "Guyanese"
    },
    {
        "alpha2Code": "HT",
        "demonym": "Haitian"
    },
    {
        "alpha2Code": "HM",
        "demonym": "Heard and McDonald Islander"
    },
    {
        "alpha2Code": "VA",
        "demonym": ""
    },
    {
        "alpha2Code": "HN",
        "demonym": "Honduran"
    },
    {
        "alpha2Code": "HK",
        "demonym": "Chinese"
    },
    {
        "alpha2Code": "HU",
        "demonym": "Hungarian"
    },
    {
        "alpha2Code": "IS",
        "demonym": "Icelander"
    },
    {
        "alpha2Code": "IN",
        "demonym": "Indian"
    },
    {
        "alpha2Code": "ID",
        "demonym": "Indonesian"
    },
    {
        "alpha2Code": "CI",
        "demonym": "Ivorian"
    },
    {
        "alpha2Code": "IR",
        "demonym": "Iranian"
    },
    {
        "alpha2Code": "IQ",
        "demonym": "Iraqi"
    },
    {
        "alpha2Code": "IE",
        "demonym": "Irish"
    },
    {
        "alpha2Code": "IM",
        "demonym": "Manx"
    },
    {
        "alpha2Code": "IL",
        "demonym": "Israeli"
    },
    {
        "alpha2Code": "IT",
        "demonym": "Italian"
    },
    {
        "alpha2Code": "JM",
        "demonym": "Jamaican"
    },
    {
        "alpha2Code": "JP",
        "demonym": "Japanese"
    },
    {
        "alpha2Code": "JE",
        "demonym": "Channel Islander"
    },
    {
        "alpha2Code": "JO",
        "demonym": "Jordanian"
    },
    {
        "alpha2Code": "KZ",
        "demonym": "Kazakhstani"
    },
    {
        "alpha2Code": "KE",
        "demonym": "Kenyan"
    },
    {
        "alpha2Code": "KI",
        "demonym": "I-Kiribati"
    },
    {
        "alpha2Code": "KW",
        "demonym": "Kuwaiti"
    },
    {
        "alpha2Code": "KG",
        "demonym": "Kirghiz"
    },
    {
        "alpha2Code": "LA",
        "demonym": "Laotian"
    },
    {
        "alpha2Code": "LV",
        "demonym": "Latvian"
    },
    {
        "alpha2Code": "LB",
        "demonym": "Lebanese"
    },
    {
        "alpha2Code": "LS",
        "demonym": "Mosotho"
    },
    {
        "alpha2Code": "LR",
        "demonym": "Liberian"
    },
    {
        "alpha2Code": "LY",
        "demonym": "Libyan"
    },
    {
        "alpha2Code": "LI",
        "demonym": "Liechtensteiner"
    },
    {
        "alpha2Code": "LT",
        "demonym": "Lithuanian"
    },
    {
        "alpha2Code": "LU",
        "demonym": "Luxembourger"
    },
    {
        "alpha2Code": "MO",
        "demonym": "Chinese"
    },
    {
        "alpha2Code": "MK",
        "demonym": "Macedonian"
    },
    {
        "alpha2Code": "MG",
        "demonym": "Malagasy"
    },
    {
        "alpha2Code": "MW",
        "demonym": "Malawian"
    },
    {
        "alpha2Code": "MY",
        "demonym": "Malaysian"
    },
    {
        "alpha2Code": "MV",
        "demonym": "Maldivan"
    },
    {
        "alpha2Code": "ML",
        "demonym": "Malian"
    },
    {
        "alpha2Code": "MT",
        "demonym": "Maltese"
    },
    {
        "alpha2Code": "MH",
        "demonym": "Marshallese"
    },
    {
        "alpha2Code": "MQ",
        "demonym": "French"
    },
    {
        "alpha2Code": "MR",
        "demonym": "Mauritanian"
    },
    {
        "alpha2Code": "MU",
        "demonym": "Mauritian"
    },
    {
        "alpha2Code": "YT",
        "demonym": "French"
    },
    {
        "alpha2Code": "MX",
        "demonym": "Mexican"
    },
    {
        "alpha2Code": "FM",
        "demonym": "Micronesian"
    },
    {
        "alpha2Code": "MD",
        "demonym": "Moldovan"
    },
    {
        "alpha2Code": "MC",
        "demonym": "Monegasque"
    },
    {
        "alpha2Code": "MN",
        "demonym": "Mongolian"
    },
    {
        "alpha2Code": "ME",
        "demonym": "Montenegrin"
    },
    {
        "alpha2Code": "MS",
        "demonym": "Montserratian"
    },
    {
        "alpha2Code": "MA",
        "demonym": "Moroccan"
    },
    {
        "alpha2Code": "MZ",
        "demonym": "Mozambican"
    },
    {
        "alpha2Code": "MM",
        "demonym": "Burmese"
    },
    {
        "alpha2Code": "NA",
        "demonym": "Namibian"
    },
    {
        "alpha2Code": "NR",
        "demonym": "Nauruan"
    },
    {
        "alpha2Code": "NP",
        "demonym": "Nepalese"
    },
    {
        "alpha2Code": "NL",
        "demonym": "Dutch"
    },
    {
        "alpha2Code": "NC",
        "demonym": "New Caledonian"
    },
    {
        "alpha2Code": "NZ",
        "demonym": "New Zealander"
    },
    {
        "alpha2Code": "NI",
        "demonym": "Nicaraguan"
    },
    {
        "alpha2Code": "NE",
        "demonym": "Nigerien"
    },
    {
        "alpha2Code": "NG",
        "demonym": "Nigerian"
    },
    {
        "alpha2Code": "NU",
        "demonym": "Niuean"
    },
    {
        "alpha2Code": "NF",
        "demonym": "Norfolk Islander"
    },
    {
        "alpha2Code": "KP",
        "demonym": "North Korean"
    },
    {
        "alpha2Code": "MP",
        "demonym": "American"
    },
    {
        "alpha2Code": "NO",
        "demonym": "Norwegian"
    },
    {
        "alpha2Code": "OM",
        "demonym": "Omani"
    },
    {
        "alpha2Code": "PK",
        "demonym": "Pakistani"
    },
    {
        "alpha2Code": "PW",
        "demonym": "Palauan"
    },
    {
        "alpha2Code": "PS",
        "demonym": "Palestinian"
    },
    {
        "alpha2Code": "PA",
        "demonym": "Panamanian"
    },
    {
        "alpha2Code": "PG",
        "demonym": "Papua New Guinean"
    },
    {
        "alpha2Code": "PY",
        "demonym": "Paraguayan"
    },
    {
        "alpha2Code": "PE",
        "demonym": "Peruvian"
    },
    {
        "alpha2Code": "PH",
        "demonym": "Filipino"
    },
    {
        "alpha2Code": "PN",
        "demonym": "Pitcairn Islander"
    },
    {
        "alpha2Code": "PL",
        "demonym": "Polish"
    },
    {
        "alpha2Code": "PT",
        "demonym": "Portuguese"
    },
    {
        "alpha2Code": "PR",
        "demonym": "Puerto Rican"
    },
    {
        "alpha2Code": "QA",
        "demonym": "Qatari"
    },
    {
        "alpha2Code": "XK",
        "demonym": "Kosovar"
    },
    {
        "alpha2Code": "RE",
        "demonym": "French"
    },
    {
        "alpha2Code": "RO",
        "demonym": "Romanian"
    },
    {
        "alpha2Code": "RU",
        "demonym": "Russian"
    },
    {
        "alpha2Code": "RW",
        "demonym": "Rwandan"
    },
    {
        "alpha2Code": "BL",
        "demonym": "Saint Barthélemy Islander"
    },
    {
        "alpha2Code": "SH",
        "demonym": "Saint Helenian"
    },
    {
        "alpha2Code": "KN",
        "demonym": "Kittian and Nevisian"
    },
    {
        "alpha2Code": "LC",
        "demonym": "Saint Lucian"
    },
    {
        "alpha2Code": "MF",
        "demonym": "Saint Martin Islander"
    },
    {
        "alpha2Code": "PM",
        "demonym": "French"
    },
    {
        "alpha2Code": "VC",
        "demonym": "Saint Vincentian"
    },
    {
        "alpha2Code": "WS",
        "demonym": "Samoan"
    },
    {
        "alpha2Code": "SM",
        "demonym": "Sammarinese"
    },
    {
        "alpha2Code": "ST",
        "demonym": "Sao Tomean"
    },
    {
        "alpha2Code": "SA",
        "demonym": "Saudi Arabian"
    },
    {
        "alpha2Code": "SN",
        "demonym": "Senegalese"
    },
    {
        "alpha2Code": "RS",
        "demonym": "Serbian"
    },
    {
        "alpha2Code": "SC",
        "demonym": "Seychellois"
    },
    {
        "alpha2Code": "SL",
        "demonym": "Sierra Leonean"
    },
    {
        "alpha2Code": "SG",
        "demonym": "Singaporean"
    },
    {
        "alpha2Code": "SX",
        "demonym": "Sint Maarten"
    },
    {
        "alpha2Code": "SK",
        "demonym": "Slovak"
    },
    {
        "alpha2Code": "SI",
        "demonym": "Slovene"
    },
    {
        "alpha2Code": "SB",
        "demonym": "Solomon Islander"
    },
    {
        "alpha2Code": "SO",
        "demonym": "Somali"
    },
    {
        "alpha2Code": "ZA",
        "demonym": "South African"
    },
    {
        "alpha2Code": "GS",
        "demonym": "South Georgia and the South Sandwich Islander"
    },
    {
        "alpha2Code": "KR",
        "demonym": "South Korean"
    },
    {
        "alpha2Code": "SS",
        "demonym": "South Sudanese"
    },
    {
        "alpha2Code": "ES",
        "demonym": "Spanish"
    },
    {
        "alpha2Code": "LK",
        "demonym": "Sri Lankan"
    },
    {
        "alpha2Code": "SD",
        "demonym": "Sudanese"
    },
    {
        "alpha2Code": "SR",
        "demonym": "Surinamer"
    },
    {
        "alpha2Code": "SJ",
        "demonym": "Norwegian"
    },
    {
        "alpha2Code": "SZ",
        "demonym": "Swazi"
    },
    {
        "alpha2Code": "SE",
        "demonym": "Swedish"
    },
    {
        "alpha2Code": "CH",
        "demonym": "Swiss"
    },
    {
        "alpha2Code": "SY",
        "demonym": "Syrian"
    },
    {
        "alpha2Code": "TW",
        "demonym": "Taiwanese"
    },
    {
        "alpha2Code": "TJ",
        "demonym": "Tadzhik"
    },
    {
        "alpha2Code": "TZ",
        "demonym": "Tanzanian"
    },
    {
        "alpha2Code": "TH",
        "demonym": "Thai"
    },
    {
        "alpha2Code": "TL",
        "demonym": "East Timorese"
    },
    {
        "alpha2Code": "TG",
        "demonym": "Togolese"
    },
    {
        "alpha2Code": "TK",
        "demonym": "Tokelauan"
    },
    {
        "alpha2Code": "TO",
        "demonym": "Tongan"
    },
    {
        "alpha2Code": "TT",
        "demonym": "Trinidadian"
    },
    {
        "alpha2Code": "TN",
        "demonym": "Tunisian"
    },
    {
        "alpha2Code": "TR",
        "demonym": "Turkish"
    },
    {
        "alpha2Code": "TM",
        "demonym": "Turkmen"
    },
    {
        "alpha2Code": "TC",
        "demonym": "Turks and Caicos Islander"
    },
    {
        "alpha2Code": "TV",
        "demonym": "Tuvaluan"
    },
    {
        "alpha2Code": "UG",
        "demonym": "Ugandan"
    },
    {
        "alpha2Code": "UA",
        "demonym": "Ukrainian"
    },
    {
        "alpha2Code": "AE",
        "demonym": "Emirati"
    },
    {
        "alpha2Code": "GB",
        "demonym": "British"
    },
    {
        "alpha2Code": "US",
        "demonym": "American"
    },
    {
        "alpha2Code": "UY",
        "demonym": "Uruguayan"
    },
    {
        "alpha2Code": "UZ",
        "demonym": "Uzbekistani"
    },
    {
        "alpha2Code": "VU",
        "demonym": "Ni-Vanuatu"
    },
    {
        "alpha2Code": "VE",
        "demonym": "Venezuelan"
    },
    {
        "alpha2Code": "VN",
        "demonym": "Vietnamese"
    },
    {
        "alpha2Code": "WF",
        "demonym": "Wallis and Futuna Islander"
    },
    {
        "alpha2Code": "EH",
        "demonym": "Sahrawi"
    },
    {
        "alpha2Code": "YE",
        "demonym": "Yemeni"
    },
    {
        "alpha2Code": "ZM",
        "demonym": "Zambian"
    },
    {
        "alpha2Code": "ZW",
        "demonym": "Zimbabwean"
    }
  ]