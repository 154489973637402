import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import default_image from "@img/image.jpg";
import GlitchClip from "react-glitch-effect/core/Clip";
const ItemTimeAgo = ({ time_ago }) => {
  return (
    <div className="item-info">
      <span>{time_ago}</span>
    </div>
  );
};

const ItemImageInner = ({ tag }) => {
  return (
    <React.Fragment>
      <div className="image-cover"></div>
      <span>{tag}</span>
      <div className="bottom-image-cover"></div>
    </React.Fragment>
  );
};

const AboveExcerpt = ({ content }) => {
  if (typeof content === "undefined") {
    return null;
  }

  return <div className="item-above-excerpt pl-2">{content}</div>;
};

const Item = (props) => {
  let { glitchDisabled = false } = props;
  let classes = "item frontend-gs-gc-box frontpage-item-collumn ";
  let imageContainerClass = "";

  if (typeof props.imageContainerClass !== "undefined") {
    imageContainerClass = props.imageContainerClass;
  }

  if (typeof props.large !== "undefined") {
    classes += " large";
  }

  if (typeof props.column !== "undefined") {
    classes += " frontpage-item-collumn col-" + props.column;
  }

  if (typeof props.classes !== "undefined") {
    classes += " " + props.classes;
  }

  if (typeof props.loading !== "undefined") {
    classes += " loading";
  }

  const openInNewWindow = props.openInNewWindow ?? false;
  const title = props.title ?? "[Deleted]";
  const excerpt = props.excerpt;
  const image = props.image ?? props.default_image ?? default_image;
  const timeAgo = moment(props.published ?? new Date()).fromNow();
  const link_to = props.link_to ?? "#";
  const hideTimeAgo = props.hide_time_ago ?? false;
  const above_excerpt = props.above_excerpt;
  const tag = props.tag;
  return (
    <div onClick={props.onClick} className={classes} style={props.gameStyle}>
      <Link target={openInNewWindow && "_blank"} to={link_to} style={{
         textDecoration: "none",
         display:"block",
         }}>
        <span class="gs-gc-top"></span>
        <span class="gs-gc-bottom"></span>
        <span class="gs-gc-left"></span>
        <span class="gs-gc-right"></span>
        <span class="gs-gc-right-corner"></span>
        <span class="gs-gc-left-corner"></span>
        <div className="item-title frontpage-title smallSlider headingGameCard pl-2" style={{ paddingTop: "39px", top: 0 }}>
          <h3>{title}</h3>
        </div>
        <Link target={openInNewWindow && "_blank"} to={link_to} style={{ textDecoration: "none" }}>
          {/* <GlitchClip disabled={glitchDisabled} duration="10s" onHover={glitchDisabled ? false : true}> */}
          <div
            className={`item-image ${imageContainerClass}`}
            style={{ backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${image})` }}
          >
            <div className="coverOpacity"> </div>
            {props.item_image_inner ?? <ItemImageInner tag={tag} />}
          </div>

          <AboveExcerpt content={above_excerpt} />
          { excerpt && <div className="item-excerpt pl-2 pb-2">{excerpt}</div> }
          {/* </GlitchClip> */}
        </Link>
      </Link>
    </div>
  );
};

export default Item;
