import React, { useEffect, useState } from "react";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import { Link } from "react-router-dom";
import { applyBrowserTimezone } from "../../../utils/time-functions";
import "./results.scss";
import useTranslated from "../../../helpers/translationHelper";

const Results = ({ t1, t1FF, t1Score, t2, t2FF, t2Score, bestOf, datetime, complete, status, isVeto, t1VetoState, t2VetoState }) => {
  // console.log('t2-------------',t2)
  const [liveTimer, setLiveTimer] = useState(moment.duration(moment().diff(moment(datetime), "milliseconds")));
  const forfeit = useTranslated('id_match_page_0')
  let _best_of_match_display = useTranslated("id_match_display_5");

  useEffect(() => {
    if (!complete) {
      setInterval(() => {
        setLiveTimer(moment.duration(moment().diff(moment(datetime), "milliseconds")));
      }, 1000);
    }
  }, [datetime, complete]);

  const pad2 = (n) => {
    return n < 10 ? "0" + n : n;
  };

  const getPill = (vetoState, forfeit, team) => {
    let pillContent
    if (isVeto && vetoState && !t1FF && !t2FF ) {
       pillContent =  <><span
          style={{ minWidth: "max-content" }}
          className={`pill ml-0 ${vetoState.toLowerCase().includes("ready") ? "success" : ""}`}
        >
          {vetoState}
        </span></>
    } else {
        pillContent = <><div className="mt-3">{forfeit && <span className={`pill danger ${team == "t1" ? "ml-0": ""}`}>{forfeit} {/* Forfeit */}</span>}</div></>
    }
    return pillContent
  }

  return (
    <React.Fragment>
      <div className="matches-header-inner">

        {t1 ? (
          t1.placeHolder ?
            <div className="matches-header-right-team">
              <div>
                <h2 className="text-22 uppercase bold mr-3">{t1.name}</h2>
                {getPill(t1VetoState, t1FF, "t1")}
              </div>
              {/* <img
                className={t1.logoCropped || t1.logoCropped === undefined ? "image-cropped" : "image-notCropped"}
                src={t1.logo}
                alt={t1.name}
              /> */}
              <img
                className="wide-page-with-tabs-logo-left"
                src={t1.logo}
                alt={t1.name}
              />
            </div> :
          <Link to={`/teams/${t1._id}`} className="matches-header-left-team">
            <div>
              <h2 className="text-22 uppercase bold mr-3">{t1.name}</h2>
              {getPill(t1VetoState, t1FF, "t1")}
            </div>

            {/* <img
              className={t1.logoCropped || t1.logoCropped === undefined ? "image-cropped" : "image-notCropped"}
              src={t1.logo}
              alt={t1.name}
            /> */}

              <img
                className="wide-page-with-tabs-logo-left"
                src={t1.logo}
                alt={t1.name}
              />
          </Link>
        ) : (
          "[DELETED]"
        )}

        <div className="matches-header-info-wrapper">
          <span className="text-14">{_best_of_match_display} {bestOf}</span>

          {moment().diff(moment(datetime), "seconds") < 0 ? (
            <>
              <span class="pill primary large">
                {t1Score} - {t2Score}
              </span>
              <span className="pt-1 match-live-timer">
                <span className="pill small danger">LIVE</span>
                {liveTimer.seconds() > 0 && (
                  <span className="text-16 pl-2">{`${pad2(liveTimer.hours())}:${pad2(liveTimer.minutes())}:${pad2(
                    liveTimer.seconds()
                  )}`}</span>
                )}
              </span>
            </>
          ) : (
            <>
              <span class="pill primary large">
                {t1Score} - {t2Score}
              </span>
              <span className="pill text-12 bold">{applyBrowserTimezone(datetime).format("Do MMM YYYY, HH:mm z")}</span>
            </>
          )}
        </div>

        {t2 ? (
          t2.placeHolder ? <div className="matches-header-right-team">
            {/* <img
              className={t2.logoCropped || t2.logoCropped === undefined ? "image-cropped" : "image-notCropped"}
              src={t2?.logo}
              alt={t2?.name}
            /> */}
            <img
                className="wide-page-with-tabs-logo"
                src={t2?.logo}
                alt={t2?.name}
              />
            <div>
              <h2 className="text-22 uppercase bold ml-3">{t2?.name}</h2>
              {getPill(t2VetoState, t2FF, "t2")}
            </div>
          </div> :
          <Link to={`/teams/${t2?._id}`} className="matches-header-right-team">
            {/* <img
              className={t2.logoCropped || t2.logoCropped === undefined ? "image-cropped" : "image-notCropped"}
              src={t2?.logo}
              alt={t2?.name}
            /> */}
            <img
                className="wide-page-with-tabs-logo"
                src={t2?.logo}
                alt={t2?.name}
              />
            <div>
              <h2 className="text-22 uppercase bold ml-3">{t2?.name}</h2>
              {getPill(t2VetoState, t2FF, "t2")}
            </div>
          </Link>
        ) : (
          "[DELETED]"
        )}
      </div>
    </React.Fragment>
  );
};

export default Results;
