import React, { useState, useRef, useEffect } from "react";
import { RiSearchLine } from "react-icons/ri";
import { FiX } from "react-icons/fi";
import Loading from "../Loading/Loading";
import { Link } from "react-router-dom";
import styled from "styled-components";

// useKeyPress custom hook
const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};

const DynamicSearch = ({
  placeholder = "Search",
  onSearch,
  onSelectResult,
  loadingResults,
  results = [],
  categorizedResults = {},
  mixedResults = false,
  newTab,
  resultStyle,
  searchInputStyle,
  schedule,
  children,
  isMainSearch = false,
  clearResult= ()=>{},
}) => {
  const queryRef = useRef();
  const [query, setQuery] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [expandedResults, setExpandedResults] = useState({})
  const [cursor, setCursor] = useState(0);
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const NewTabOpen = newTab ? NewTabOpener : React.Fragment;

  const onQueryChange = (e) => {
    const val = e.target.value;
    setQuery(val);
    console.log('val: ')
    if(val.trim() == '') return
    console.log('val after:', val)
    onSearch(val); // Component callback, for running search logic outside the component
    setCursor(0); // reset the keyboard selection back to first item
  };


  const handleListResults = (results, expanded) => {
    let displayedResults =  expanded ? results : results.slice(0, 3)
    return displayedResults.map(({ _id, img, title, subtitle, type, user, player, coach, logoCropped }, i) => (
      <ResultContainer onClick={() => onClickResult({ _id, type, img, title, subtitle, user, player, coach, logoCropped })}> 
        <TitleContainer>
          {img ? 
            (
              <ResultThumbnail
                cropped={logoCropped}
                imgUrl={img}
              />
            )
           : 
            (
              <div className="profile-img-with-inital-1-letter">{title.charAt(0)}</div>
            )
          }
        { mixedResults && 
          (
            <>
              { title && <Title>{title}</Title> }
              { subtitle && <Subtitle className="subtitle">{subtitle}</Subtitle> }
            </>
          )
        }
      </TitleContainer>
      <RegionBadge>EUW</RegionBadge>
    </ResultContainer>
      // render each search result
      
    ))
  }

  const expandCategory = (category) => {
    setExpandedResults(prev => ({
      ...prev,
      [category]: !prev[category]
    }))
  }

  const onClickResult = (result) => {
    onSelectResult(result); // Component callback, for sending selected result outside of component
    
    // clear query
    onSearch("");
    setQuery("");
    queryRef.current.blur(); // take focus out of search box
    clearResult()
  };

  useEffect(() => {
    console.log('results:', results)
  }, results)

  //
  // KEYBOARD STUFF
  //

  // If down key pressed
  useEffect(() => {
    if (results.length && downPress) {
      setCursor((prevState) => (prevState < results.length - 1 ? prevState + 1 : 0));
    }
  }, [results, downPress]);

  // If up key pressed
  useEffect(() => {
    if (results.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : results.length - 1));
    }
  }, [results, upPress]);

  // If enter pressed
  useEffect(() => {
    if (results.length && enterPress && showResults) {
      onSelectResult(results[cursor]); // Component callback, for sending selected result outside of component

      // clear query
      onSearch("");
      setQuery("");
      queryRef.current.blur(); // take focus out of search box
    }
  }, [results, cursor, enterPress, onSearch, onSelectResult, showResults]);


  // Reset cursor when results hide/show
  useEffect(() => {
    setCursor(0);
    console.log('Query lenght =>',query.length);
    console.log('Query => ',query)
  }, [showResults,query.length,query]);


  useEffect(() => {
    if(Object.keys(categorizedResults).length > 0 || loadingResults || results.length > 0){
      console.log('results:', categorizedResults)
      setShowResults(true)
    } else {
      setShowResults(false)
    }

  }, [categorizedResults])

  return (
    <SearchWrapper>
      {children}
      <InputWrapper>
        <SearchInput
          ref={queryRef}
          type="text"
          id="Main_Search"
          className={`form-input ${!schedule && "notSchedule"}`} //if it is not schedule then we need to add this class.
          placeholder={placeholder}
          value={query}
          onChange={onQueryChange}
          onFocus={() => {
            onSearch(query)
            Object.keys(categorizedResults).length > 0  || results.length > 0 || loadingResults ? setShowResults(true) : setShowResults(false)
          }}
          onBlur={(e) => {
            setShowResults(false);
            e.target.value = e.target.value.trim();
          }}
          autoComplete="off"
          style={searchInputStyle}
        />
        {query.length !== 0 ? (
          <FiX
            className="input-icon-big"
            onClick={() => {
              onSearch("");
              setQuery("");
              queryRef.current.focus();
            }}
          />
        ) : (
          <RiSearchLine className="input-icon-big" />
        )}
        {/* <RiSearchLine className="input-icon" /> */}
      </InputWrapper>
      <DynamicSearchResults
        onMouseDown={(e) => e.preventDefault()}
        style={resultStyle}
        show={showResults}
        expandedWidth={isMainSearch}
      >
        {/* Results is an array with 3 possible keys: _id, img, title, subtitle */}
        { loadingResults ? (
          <div className="search-result results-loading">
            <Loading width={27} height={27} />
          </div>
        ) : results.length ? handleListResults(results, false) :
         !Object.keys(categorizedResults).length && !results.length && query ? (
          <div className="search-result no-results">{query.length < 3 ? "Try typing more characters..." : "No results found."}</div>
        ) : categorizedResults && (
          Object.entries(categorizedResults).map(([type, searchResults]) => (
            <div>
              <ResultHeader>
                <LeftSpan>
                  {type.toUpperCase()}
                  <Badge>
                    {searchResults.length}
                  </Badge>
                </LeftSpan>
                { searchResults.length > 3 ?  (
                  <ShowAllButton onClick={() => expandCategory(type)}>
                    {!expandedResults[type] ? 'SEE ALL' : 'SEE LESS'}
                  </ShowAllButton>
                ) : null}
                
              </ResultHeader>

              { handleListResults(searchResults, expandedResults[type]) }
              
              
            </div>
          ))
          
          ) 
          }
          
          
      </DynamicSearchResults>
    </SearchWrapper>
  );
};

const DynamicSearchResults = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  position: absolute;
  top: 100%;
  border-top: 0;
  border-right: 1px;
  border-bottom: 1px;
  border-left: 1px;
  border-style: solid;
  border-color: var(--color-hr);
  border-radius: 0 0 2px 2px;
  max-height: 400px;
  width: ${props => props.expandedWidth ? 'calc(100% + 35px)' : '100%'};
  overflow-y: scroll;
  background: var(--color-bg-dropdown);
  z-index: 10;
  
  ::-webkit-scrollbar {
    display: block;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #2F3136;
  }

  ::-webkit-scrollbar-track {
    border-radius: 0px;
  }
  
  & .results-loading {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 12.5px;
    cursor: pointer;
    justify-content: space-around;
  }
`

const ResultThumbnail = styled.img`
  width: 30px;
  height: 30px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: ${props => props.cropped ? '0%' : '100%'};
  ${props => props.imgUrl && 'background-image: url("'+ props.imgUrl +'");'}
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12.5px;
  cursor: pointer;
`

const Title = styled.small`
  display: inline-flex;
  font-size: font-size: 0.9rem;
  padding-left: 10px;
`

const Subtitle = styled.small`
  display: inline-flex;
  font-size: 0.75rem;
  color: #999;
  padding-left: 10px;
`

const LeftSpan = styled.span`
  display: flex;
  align-items: center;
  font-weight: bold;
`

const ShowAllButton = styled.span`
  color: var(--primary);
  font-size: 0.9em;
  font-weight: bold;
  cursor: pointer;
`


const ResultHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
`

const Badge = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--primary);
    font-weight: bold;
    font-size: 1em;
    padding: 10px;
    height: 1.7em;
    width: 1.7em;
    margin-left:8px;
    font-size: 0.9em;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--primary);
      border-radius: 50%;
      opacity: 0.1;
      z-index: 0;
  }
`
const CountBadge = styled.span`
    

  
`

const RegionBadge = styled(Badge)`
  width: auto;
  height: auto;
  padding: 5px 10px;
  &::before {
    border-radius: 30px;
  }
`
const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`

const SearchInput = styled.input`
  border: none;
  padding: 10px 15px;
  &:focus, &:hover {
    box-shadow: none
  }
`

export default DynamicSearch;

const NewTabOpener = ({ result, children }) => {
  const { _id, type } = result;
  let linkToPage;
  switch (type) {
    case "User":
      linkToPage = `/players/${_id}`;
      break;
    case "Player":
      linkToPage = `/game-profile/${_id}`;
      break;
    case "Organisation":
      linkToPage = `/organisation/${_id}`;
      break;
    case "Team":
      linkToPage = `/teams/${_id}`;
      break;
    case "Coach":
      linkToPage = `/coaches/${_id}`;
      break;
    default:
  }

  return (
    <Link to={linkToPage} className="redirect-link">
      {children}
    </Link>
  );
};
