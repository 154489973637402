import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import default_image from "@img/image.jpg";
// import GlitchClip from "react-glitch-effect/core/Clip";
import { Row, Col } from "react-bootstrap";
import { GlitchImage } from "@common";
import useTranslated from "../../../helpers/translationHelper";
const ItemTimeAgo = ({ time_ago }) => {
  const month=useTranslated('id_news_page_10')
  const fewSeconds=useTranslated('id_news_page_11')
  const years=useTranslated('id_news_page_12')
  const ago=useTranslated('id_news_page_13')

  let timereplace="";
  if (time_ago.includes("months") && month){
    // console.log(time_ago);
    timereplace=time_ago.replace("months",month)
  }
  else if (time_ago.includes("few seconds") && fewSeconds){
    // console.log(time_ago);
    timereplace=time_ago.replace("few seconds",fewSeconds)
  } else if (time_ago.includes("year") && years){
    // console.log(time_ago);
    timereplace=time_ago.replace("year",years)
  }

  else{
    timereplace=time_ago
  }
  if (time_ago.includes("ago") && ago){
    // console.log(time_ago);
    timereplace=timereplace.replace("ago",ago)
  }
  return (
    <div className="item-info">
      <span>{timereplace}</span>
    </div>
  );
};

const ItemImageInner = ({ tag }) => {
  if (typeof tag === "undefined" || tag === null) {
    return null;
  }
  return (
    <React.Fragment>
      <span>{tag}</span>
    </React.Fragment>
  );
};

const TeamsGameIconInner = ({ imgContent }) => {
  if (typeof imgContent === "undefined" || imgContent === null) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="game-image-cover">
        <img src={imgContent} className="players-game-icon" alt={`Game`} />
      </div>
    </React.Fragment>
  );
};

const AboveExcerpt = ({ content }) => {
  if (typeof content === "undefined") {
    return null;
  }

  return <div className="item-above-excerpt pl-2 ">{content}</div>;
};

const Item = (props) => {
  let { glitchDisabled = false, browseGame } = props;
  let classes = "item";
  let imageContainerClass = "";

  if (typeof props.imageContainerClass !== "undefined") {
    imageContainerClass = props.imageContainerClass;
  }

  if (typeof props.large !== "undefined") {
    classes += " large";
  }

  if (typeof props.column !== "undefined") {
    classes += " col-" + props.column;
  }

  if (typeof props.classes !== "undefined") {
    classes += " " + props.classes;
  }

  if (typeof props.loading !== "undefined") {
    classes += " loading";
  }

  const openInNewWindow = props.openInNewWindow ?? false;
  const title = props.title ?? "[Deleted]";
  const excerpt = props.excerpt;
  const image = props.image ?? props.default_image ?? default_image;
  const timeAgo = moment(props.published ?? new Date()).fromNow();
  const link_to = props.link_to ?? "#";
  const hideTimeAgo = props.hide_time_ago ?? false;
  const above_excerpt = props.above_excerpt;
  const tag = props.tag;
  const bgSize = props.backgroundSize;
  return (
    <div onClick={props.onClick} className={`${Number(props.column) === 1 ? "item column1" : classes}`} style={props.style}>
      {/* <Row className="smaller-gutter-column">
        <Col xs={12} md={8} lg={12}> */}
      <Link
        target={openInNewWindow && "_blank"}
        to={link_to}
        style={{
          textDecoration: "none",
        }}
        className={`${props.link_to === "/news/not-found" ? "disabled_link" : ""}`}
      >
        <div className="overflow-hidden">
        <div className="thumbnail-container">
        {/* <GlitchClip disabled={glitchDisabled} duration="4s" onHover={glitchDisabled ? false : true}> */}
        <GlitchImage
          className={`item-image ${imageContainerClass}`}
          style={{
            /* width: "368px", */
            /* height: "207px", */
            backgroundPosition: "center",
            boxShadow: 'none',
            backgroundImage: `linear-gradient(to bottom, transparent, #03060D) ,url(${image})`,
            backgroundSize: bgSize ? bgSize : "100%",
            borderTopRightRadius: "14px",
          }}
        >
          {props.item_image_inner ?? <ItemImageInner tag={tag} />}
          <TeamsGameIconInner imgContent={props.team_game_icon_inner} /> {/* Only for team Item */}
          {browseGame && (
            <>
              <Link
                className="browse-game gotogameButton"
                target={openInNewWindow && "_blank"}
                to={link_to}
                style={{ textDecoration: "none" }}
              >
                <h3 style={{ fontSize: "17px" }}>GO TO GAME</h3>
              </Link>
              <div className="coverOpacity"> </div>
            </>
          )}
        </GlitchImage>

        {!hideTimeAgo ? <ItemTimeAgo time_ago={timeAgo} /> : null}
        {/* </GlitchClip> */}
        <div className="item-title pl-2">
          <h3>{title}</h3>
        </div>
        <AboveExcerpt content={above_excerpt} />
        <div className="item-excerpt pl-2 pb-2">{excerpt}</div>
        </div>
        </div>
      </Link>

      <span className="gc-top"></span>
      <span className="gc-bottom"></span>
      <span className="gc-left"></span>
      <span className="gc-right"></span>
      <span className="gc-right-corner"></span>
      <span className="gc-left-corner"></span>
      {/* </Col> 
     </Row>  */}
    </div>
  );
};

export default Item;
