import Axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { List, TeamMemberListItem, MatchListingByDate } from "@common";
import { SortableList, Icon, Loading } from "@common";
import UpcomingMatch from "../../../../../YourMatches/YourMatchesTabs/OngoingUpcoming/UpcomingMatch";
import { getUserRole } from "../../../../../../utils/checkReschedule";
import { AppContext } from "../../../../../../context/AppContext";
import SetPlayers from "../../../../../Scrims/ScrimsSingle/ScrimsSingleTabs/Members/SetPlayers/SetPlayers";
import { scrimCheckinToMins } from "../../../../../../utils/time-functions";
import moment from "moment";

const ActiveForNextMatch = ({ team, isOwner, isManager }) => {
  const {
    user: {
      state: { _id: UserId },
    },
  } = useContext(AppContext);
  const [players, setPlayers] = useState([]);
  const [match, setMatch] = useState(null);
  const [teamSelector, setTeamSelector] = useState("t1");
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const league = match ? (match.league ? match.league : match.tournament ? match.tournament : match.scrim) : {};
  const date = match?.datetime;
  const { user: authUser } = useContext(AppContext);

  const [setPlayersDeadline, setSetPlayersDeadline] = useState(360); // league.setPlayersDeadline will be 6 hours in mins when league not exist for tournaments

  useEffect(() => {
    (league && league.setPlayersDeadline && setSetPlayersDeadline(league.setPlayersDeadline)) ||
      (league && league.checkInPeriod && setSetPlayersDeadline(scrimCheckinToMins(league.checkInPeriod)));
  }, [league]);

  useEffect(() => {
    let url =
      team.game.shortName === "CSGO"
        ? `${process.env.REACT_APP_CSGO_API}/api/matches/${team._id}/activeForNextMatch`
        : `${process.env.REACT_APP_LOL_API}/api/teams/${team._id}/activeForNextMatch`;
    Axios.get(url).then(({ data }) => {
      setPlayers(data.activeForNextMatch);
      setMatch(data.nextMatch);
      if (data.nextMatch) {
        console.log("active next match -- ");
        setTeamSelector(data.nextMatch.t1._id.toString() === team._id ? "t1" : "t2");
      }
      setLoading(false);
    });
  }, [team._id]);

  const getPlayerPositionData = (role = "") => {
    return (team.game || {}).configuration.playerTypes.find(({ name }) => name === role);
  };

  const isWithinDeadline = moment(date).diff(moment(), "minutes") >= setPlayersDeadline && moment(date).diff(moment(), "hours") < 336;
  // Decide whether setting players is allowed...
  const showSetPlayers = () => {
    const { members: teamMembers = [], organisation } = team;
    let isOrgOwner = false;
    let isTeamMember = false;
    if (organisation?.owners?.find(({ user }) => user === authUser?.state?._id || user?._id === authUser?.state?._id)) {
      isOrgOwner = true;
    }
    if (
      teamMembers.find(
        ({ user, coach }) => (user && user._id === authUser?.state?._id) || (coach && coach?.user?._id === authUser?.state?._id)
      )
    ) {
      isTeamMember = true;
    }
    // let userMember = teamMembers.find(
    //   ({ user, coach }) => (user && user._id === authUser?.state?._id) || (coach && coach.user._id === authUser?.state?._id)
    // );
    // if (userMember?.isCaptain) {
    //   isTeamMember = true;
    // }
    // debugger;
    if (isWithinDeadline && (isOrgOwner || isTeamMember)) {
      return true;
    } else {
      return false;
    }
  };
  const isCaptain = () => {
    const { members: teamMembers = [], organisation } = team;
    const userMember = teamMembers.find(
      ({ user, coach }) =>
        (user && user._id === authUser.state._id) ||
        (coach && typeof coach.user !== "object" && coach.user === authUser.state._id) ||
        (coach && typeof coach.user == "object" && coach.user._id === authUser.state._id)
    );
    if (userMember?.isCaptain || userMember.coach) {
      return true;
    }
    return false;
  };
  console.log("ss", showSetPlayers);
  // let canSetPlayers = showSetPlayers(); // As of the Team Captain Doc (Owner,Manager and captain can set players)
  let canSetPlayers = isOwner || isManager || isCaptain();

  return (
    <>
      {!loading ? (
        <div>
          <div className="row mb-4">
            <div className="col-3">
              <span className="text-faded uppercase bold">Players</span>
            </div>
            <div className="col-9">
              <List>
                {!players.length
                  ? "Players not set for next match."
                  : players.map(({ _id, role, player, isCaptain }) => {
                      console.log({ players });
                      return (
                        <TeamMemberListItem
                          key={_id}
                          img={player && player.avatarImage}
                          title={player && player.name}
                          subtitle={player && `${player.user.firstName} ${player.user.lastName}`}
                          position={getPlayerPositionData(role)}
                          role={role}
                          nationality={player && player.user.nationality}
                          isCaptain={isCaptain}
                        />
                      );
                    })}
              </List>
            </div>
          </div>
          {/* {match && getUserRole({ members: team.members, owners: team.organisation.owners }, null, UserId).toLowerCase() !== "player" && (
            <div className="row">
              <div className="col-12">
                <SortableList
                  classes="your-matches-sortable-list"
                  config={[
                    { col: 2, title: "Date", prop_name: "date", is_sortable: true },
                    { col: 6, title: "Match", prop_name: "match", is_sortable: true },
                    { col: 2, title: "Status", prop_name: "status", is_sortable: true, alignment: "center" },
                    { col: 2, title: "Actions", prop_name: "actions", is_sortable: true },
                  ]}
                >
                  {[match].map((match, i) => {
                    if ((match?.league || match?.tournament) && match?.t1 && match?.t2) {
                      return [match[teamSelector]].map((team) => (
                        <UpcomingMatch
                          match={match}
                          matches={[match]}
                          team={team}
                          teamSelector={teamSelector}
                          setMatches={setMatches}
                          setPlayers={setPlayers}
                        />
                      ));
                    }
                  })}
                </SortableList>
              </div>
            </div>
          )} */}
          {match && (
            <div className="col-12">
              <MatchListingByDate
                existingMatches={[match]}
                isRecent={true}
                page={"ACTIVE_NEXT_MATCH"}
                showSetPlayers={() => setPlayers}
                canSetPlayers={canSetPlayers}
                fullScreen={true}
                teamSelector={teamSelector}
                renderSetPlayers={
                  <div style={{ padding: "0px 20px" }}>
                    <SetPlayers
                      {...match}
                      setPlayersDeadline={league?.setPlayersDeadline || scrimCheckinToMins(league?.checkInPeriod)}
                      teamData={team}
                      match={match}
                      teamSelector={teamSelector}
                      updateMatch={(uMatch) => {
                        let leagueOrTournament = uMatch.league || uMatch.tournament || uMatch.scrim;
                        let data = leagueOrTournament.game?.shortName == "CSGO" ? uMatch.csgoData : uMatch.lolData;
                        let finalData;
                        if (leagueOrTournament.game?.shortName == "CSGO") {
                          finalData = { ...match, csgoData: data };
                        } else {
                          finalData = { ...match, lolData: data };
                        }
                        setMatch(finalData);
                        setPlayers(data.players[teamSelector]);
                      }}
                    />
                  </div>
                }
              ></MatchListingByDate>
            </div>
          )}
        </div>
      ) : (
        <div className="text-center mt-3">
          <Loading width={50} height={50} />
        </div>
      )}
    </>
  );
};

export default ActiveForNextMatch;
