import React, { useContext, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import Dropdown from "./Dropdown/Dropdown";
import default_profile_picture from "@img/rallerpenos-image.png";
import logo_header from "@img/logo-header.svg";
import { DynamicSearch, Icon } from "@common";
import NotificationsDropdown from "./NotificationsDropdown/NotificationsDropdown";
import GameSwitcher from "./GameSwitcher/GameSwitcher";
import { useState } from "react";
import Axios from "axios";
import GlitchText from "../../_common/GlitchText/GlitchText";
import useTranslated from "../../../helpers/translationHelper";
import LocalizationDropdown from "./LocalizationDropdown/LocalizationDropdown";
import styled from "styled-components";
import Search from "./Search/Search";
import { HiGlobeAlt } from "react-icons/hi";
import { GoSettings } from 'react-icons/go'

const toggleSidebar = (sidebarOpen, setSidebarOpen) => {
  setSidebarOpen(!sidebarOpen);
  localStorage.setItem("sidebarOpen", !sidebarOpen);
};


const useStateWithLocalStorage = (key, initialValue) => {

  const [state, setState] = useState(() => {
    const storedValue = localStorage.getItem(key)
    return storedValue ? JSON.parse(storedValue) : initialValue
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])

  return [state, setState]
}
 
const HeaderButtons = () => {
  const history = useHistory();
  const { authenticated, setAuthenticated, user, setShowAuth } = useContext(AppContext);


  

  const Leagues_Admin=useTranslated('id_top_bar_(settings)_0')
  const Ghost_Dashboard=useTranslated('id_top_bar_(settings)_1')
  const Settings=useTranslated('id_top_bar_(settings)_2')
  const Logout=useTranslated('id_top_bar_(settings)_1')
  const organizerUrl = process.env.REACT_APP_ORGANIZER_URL ?? 'https://test-organizer.leagues.gg/'
  const session = localStorage.getItem("sessionToken");
  const logout = () => {
    localStorage.removeItem("sessionToken");
    setAuthenticated(false);
    history.push("/");
  };

  const checkOrganaizerPermission = (user)=> {
    if(user){
      console.log("Share Organaizers",user.sharedOrganizer)
      if(user?.permissions?.user?.OrganizerAccess || user?.permissions?.user?.OrganizerAdmin) return true;
      if(user?.sharedOrganizer > 0) return true;
    }
    return false
  }
  let usertranslate1 = useTranslated("id_top_bar_3");
  let usertranslate2 = useTranslated("id_top_bar_4");
  if (authenticated && user.state) {
    return (
      <React.Fragment>
        <Link to={`/players/${user.state._id}`} className="header-profile-link">
          <img src={user.state.avatarImage || default_profile_picture} style={{ borderRadius: "15px" }} alt="" />
          <span className="bold">{user.state.nickname || user.state.firstName}</span>
        </Link>
        <LocalizationDropdown icon={<HiGlobeAlt/>} hasCaret={true} />

        <NotificationsDropdown />

        <Dropdown
          label={<Icon name="cog" />}
          render={() => (
            <>
              {user.state.admin ? (
                <>
                  <a href="https://admin.leagues.gg" target="_blank" rel="noopener noreferrer" className="header-settings-link">
                    <Icon name="cog" /> {Leagues_Admin}
                  </a>
                  <a href="https://pr.leagues.gg/ghost/#/posts" target="_blank" rel="noopener noreferrer" className="header-settings-link">
                    <Icon name="cog" /> {Ghost_Dashboard}
                  </a>
                  <a href={`${organizerUrl}?lst=${session}`} target="_blank" rel="noopener noreferrer" className="header-settings-link">
                    <Icon name="hiChip" /> {'Organizer Panel'}
                  </a>
                </>
              ):
               (checkOrganaizerPermission(user?.state) &&
              //  ((user?.state?.permissions?.user.OrganizerAccess === true || user?.state?.permissions?.user.OrganizerAdmin === true) &&
                 <a href={`${organizerUrl}?lst=${session}`} target="_blank" rel="noopener noreferrer" className="header-settings-link">
                 <Icon name="hiChip" /> {'Organizer Panel'}
               </a>)
              }
              <Link to="/account" className="header-settings-link">
                <Icon name="cog" /> {Settings}
              </Link>
              <div onClick={logout} className="header-settings-link">
                <Icon name="logout" /> {Logout}
              </div>
            </>
          )}
        />

        {/* <UpcomingMatch /> */}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <LocalizationDropdown icon={<HiGlobeAlt/>} hasCaret={true} />
      <button className="button bordered medium ml-3 mr-3" onClick={() => setShowAuth("login")}>
      {usertranslate1}
      </button>
      <button className="button primary medium" onClick={() => setShowAuth("register")}>
      {usertranslate2}
      </button>
    </React.Fragment>
  );
};

const FilterIcon = styled(GoSettings)`
  font-size: 1.2em;
`

const FilterButton = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-top: 1px;
  border-right: 1px;
  border-bottom: 1px;
  border-left: 0;
  border-style: solid;
  border-color: #2C2C2C;
  /* border-left: none; */
  aspect-ratio: 1/1;
  min-width: 35px;
  &:hover {
    background: #22262E;
  }
`

const TypeSelectorButton =  ( {onClick} ) => (
  <FilterButton onClick={onClick}>
    <FilterIcon />
  </FilterButton>
)

const Header = ({ sidebarOpen, setSidebarOpen }) => {
  const history = useHistory();
  const timeout = useRef();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions]=useState([])

  const [types, setTypes] = useStateWithLocalStorage('filterTypes', [])
  const [selectedTypes, setSelectedTypes] = useStateWithLocalStorage('selectedFilterTypes', [])
  const { selectedGame, setSelectedGame } = useContext(AppContext);

  useEffect(async () => {
    fetchTypeFilters() 
  }, [])

  useEffect(async () => {
    const gameRegions = JSON.parse(localStorage.getItem(`regions${selectedGame.shortName}`))
    if(!gameRegions) return
    setSelectedRegions([gameRegions[0]])
    setRegions(gameRegions)
  }, [selectedGame.shortName])


  const createRegionSelectorLabel = () => {
    console.log("SELECTED TEST", selectedRegions)
    if(selectedRegions.length < 3) {
        return selectedRegions.map(region => region.code).join(", ")
    } else if (selectedRegions.length >= 3 && selectedRegions.length < regions.length) {
        return selectedRegions[0].code + ", " + selectedRegions[1].code + ", ..."
    } else {
        return "All"
    }
  }



  const fetchTypeFilters = async () => {
    // if filter types are set in localStorage, do not request from API.
    const filterTypes = JSON.parse(localStorage.getItem('filterTypes'))

    if(!filterTypes.length) {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/globalSearch/filtertypes`).then(({ data }) => {
        setTypes(data);
        setSelectedTypes(data)
      });
    } else {
      const selectedFilterTypes = JSON.parse(localStorage.getItem('selectedFilterTypes'))
      selectedFilterTypes && setSelectedTypes(selectedFilterTypes)
      setTypes(filterTypes)
    }
  }

  const onSearch = async (query) => {
    clearTimeout(timeout.current);
    setResults([]);
    if (!query.length) return setLoading(false);
    setLoading(true);
    let selectedGameId = null;
    if (selectedGame.shortName !== "No Game") {
      selectedGameId = selectedGame._id;
    }

    timeout.current = setTimeout(async () => {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/globalSearch`, {
        query,
        include: selectedTypes?.join(', '),
        selectedGameId,
        selectedRegionIds: selectedRegions?selectedRegions.map(region => region._id):''
      });

      // Map data to required output, and remove already selected players
      const searchResults = data.map(({ _id, img, name, user, type, logoCropped }) => ({
        _id,
        type,
        img,
        logoCropped,
        title: name,
        subtitle: user ? `${user.firstName} ${user.lastName}` : false,
      }));

      const searchResultsByType  = searchResults.reduce((acc, result) => {
        if (!acc[result.type]) {
          acc[result.type] = [];
        }
        acc[result.type].push(result);
        return acc;
      }, {});

      console.log(searchResultsByType)
      setResults(searchResultsByType);
      setLoading(false);
    }, 250);
  };

  
  const onClickSearchResult = (result) => {
    const { _id, type } = result;

    switch (type) {
      case "User":
        history.push(`/players/${_id}`);
        break;
      case "Player":
        history.push(`/game-profile/${_id}`);
        break;
      case "Organisation":
        history.push(`/organisation/${_id}`);
        break;
      case "Team":
        history.push(`/teams/${_id}`);
        break;
      case "Coach":
        history.push(`/coaches/${_id}`);
        break;
      default:
    }
  };
  
  return (
    <React.Fragment>
      <header className="global-header">
        <div className="header-logo">
          <button className="menu-icon" onClick={() => toggleSidebar(sidebarOpen, setSidebarOpen)}>
            {sidebarOpen ? <Icon name="menu-close" /> : <Icon name="menu-open" />}
          </button>
          <div style={{ cursor: "pointer" }}>
            <Link to="/" className="logo-text">
              <GlitchText animateOnHover>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={logo_header} alt="Leagues logo" />
                  <strong className="uppercase logo__text">{useTranslated('id_top_bar_0')}</strong>
                </div>
              </GlitchText>
            </Link>
          </div>
          <span className="header-game-switcher-bleft">&nbsp;</span>
          <GameSwitcher />
        </div>
        

        <Search
          onSearch={onSearch}
          regionOptions={regions}
          selectedRegions={selectedRegions} 
          setSelectedRegions={setSelectedRegions}
          onClickSearchResult={onClickSearchResult}
          loading={loading}
          categorizedResults={results}
          labelHandlerRegions={createRegionSelectorLabel}
          typeOptions={types}
          selectedTypes={selectedTypes}
          setSelectedTypes={setSelectedTypes}
          typeCustomButton={<TypeSelectorButton />}
          />
        {/* <div>
          { selectedregions ?
            <select className="form-input" onChange={(event)=>handleSelectedRegion(event)} >
              <option value="">
                -- Choose Region --
              </option>
              {selectedregions &&
                selectedregions.map((item, index) => {
                  return (
                    <option className="test1" value={item.code}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          ) : null}
        </div>
        <MainSearchWrapper>
          <DynamicSearch
            placeholder={useTranslated("id_top_bar_2")}
            onSearch={onSearch}
            onSelectResult={onClickSearchResult}
            loadingResults={loading}
            results={results}
            mixedResults={true}
            newTab
          />
        </MainSearchWrapper> */}

        <div className="header-actions">
          <HeaderButtons />
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;











