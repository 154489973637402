import React, { useContext, useState, useEffect } from "react";
import { Loading } from "@common";
import Dropdown from "./../Dropdown/Dropdown";
import { AppContext } from "../../../../context/AppContext";
import Axios from "axios";
import { Link } from "react-router-dom";
import Collapsible from "react-collapsible";
import useTranslated from "../../../../helpers/translationHelper";
import {BiChip} from 'react-icons/bi'
const GameSwitcherLink = ({Icon, icon, name, onClick }) => {
  return (
    <div onClick={onClick} className="game-switcher-link text-12 bold pt-2 pb-2">
       {Icon ? <Icon className='icon'/> : <div className="icon" style={{ backgroundImage: `url(${icon})` }} />}
     {Icon ? ` ${name}` : name}
    </div>
  );
};

const GameSwitcher = ({ game, setGame }) => {
  const { user,selectedGame, setSelectedGame } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState([]);
  let usertranslate = useTranslated("id_top_bar_1");

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      setGames(() => {
        return data.filter((game) => game.name !== "No Game");
      });
      setLoading(false);
    });
  }, []);
  const checkOrganaizerPermission = (user)=> {
    if(user){
      if(user?.permissions?.user?.OrganizerAccess || user?.permissions?.user?.OrganizerAdmin) return true;
      if(user?.sharedOrganizer > 0) return true;
    }
    return false
  }
  const onSwitch = (game) => {

    let session = localStorage.getItem("sessionToken");
    switch (game.name) {
      case "League of Legends":
        if (session) window.location.href = `${process.env.REACT_APP_LOL_URL}?lst=${session}&shortName=LOL`;
        else window.location.href = `${process.env.REACT_APP_LOL_URL}?shortName=LOL`;
        break;
      case "Valorant":
        if (session) window.location.href = `${process.env.REACT_APP_VALORANT_URL}?lst=${session}&shortName=VL`;
        else window.location.href = `${process.env.REACT_APP_VALORANT_URL}?shortName=VL`;
        break;
      case "CS:GO":
        console.log(`${process.env.REACT_APP_CSGO_URL}?lst=${session}`);
        if (session) window.location.href = `${process.env.REACT_APP_CSGO_URL}?lst=${session}&shortName=CSGO`;
        else window.location.href = `${process.env.REACT_APP_CSGO_URL}?shortName=CSGO`;
        break;
       case "Organize":
        if (session) window.location.href = `${process.env.REACT_APP_ORGANIZER_URL}?lst=${session}`;
        else window.location.href = `${process.env.REACT_APP_ORGANIZER_URL}`;;
        break;
      default:
        setSelectedGame(game);
        window.location.reload();
        break;
    }
  };
  // const onSwitch = (game) => {
  //   setSelectedGame(game);
  //   window.location.reload();
  // };


  const onSwitchExternal = () => {
    let session= localStorage.getItem('sessionToken')
    if(session)
    window.location.href=`${process.env.REACT_APP_VALORANT_URL}?lst=${session}`
    else
    window.location.href=process.env.REACT_APP_VALORANT_URL
  };
  useEffect(() => {
    if (games) {
      if (checkOrganaizerPermission(user?.state)) {
        const x = games.filter((game) => game.name === "Organize");
        if (x.length < 1) {
          setGames((state) => [ { name: "Organize",Icon:BiChip },...state]);
        }
      } else {
        games.map((game) => {
          if (game.name === "Organize") {
            setGames(() => {
              return games.filter((game) => game.name !== "Organize");
            });
          }
        });
      }
    }
  }, [games, user]);
  return (
    <Dropdown
      games={games && games.length}
      isGameSwitcher={true}
      image={selectedGame && selectedGame.whiteIcon}
      label={selectedGame && selectedGame.shortName}
      showCaret
      dynamic_width
      className="header-game-switcher"
      render={(open, setOpen) =>
        loading ? (
          <Loading width={20} height={20} className="loaderForGamesSelection" />
        ) : (
          <>
            {/* <Collapsible transitionTime={100} classParentString="collapsible-wrapper"> */}
            <div className="game-switcher-link text-12 bold pt-2 pb-2">
              <div className="icon" />
              <Link to={"/browse-games"} className={"primary-link"}>
                {usertranslate}
              </Link>
            </div>
            {games.map((game, i) => (
              <GameSwitcherLink
                key={i}
                icon={game.whiteIcon}
                name={game.name}
                Icon={game.Icon ?? false}
                onClick={() => {
                  // if (game.name === "Valorant") {
                  //   window.open(process.env.REACT_APP_VALORANT_URL, "_blank");
                  // } else 
                  // if (game.name === "Organizer") {
                  //   window.open("https://test-organizer.leagues.gg/", "_self");
                  // } else {
                    onSwitch(game);
                    setOpen(false);
                  // }
                }}
              />
            ))}
            {/* </Collapsible> */}
          </>
        )
      }
    />
  );
};

export default GameSwitcher;