import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import Tippy from "@tippyjs/react";
import { getCurrentTimeZone, isMatchLive } from "@utils";
import { useHistory } from "react-router-dom";
import { ordinalSuffixOf } from "../../../utils/additional-helpers";
import moment from "moment";
import { Link } from "react-router-dom";
import { Icon } from "..";
import { scrimCheckinToMins } from "../../../utils/time-functions";
import SetPlayers from "../../YourMatches/YourMatchesTabs/OngoingUpcoming/SetPlayers/SetPlayers";
import TempTournCodes from "../../YourMatches/YourMatchesTabs/OngoingUpcoming/TempTournCodes/TempTournCodes";
import { applyBrowserTimezone } from "../../../utils/time-functions";
import { ReactComponent as SetPlayersUp } from "../../../img/set-players-up.svg";
import { ReactComponent as SetPlayersDown } from "../../../img/set-players-down.svg";
import useTranslated from "../../../helpers/translationHelper";
import _ from "lodash";
import imglogo from "../../../img/default-team-logo.png"
const ScheduleMatchesItem = ({
  match,
  id,
  fullScreen,
  page,
  queryId,
  teamSelector,
  team = {},
  userTeams = [],
  stagesLength,
  stage,
  index,
  onHover,
  onLeave,
  groupIndex,
  canSetPlayers,
  renderSetPlayers,
  mainItemId,
  isOwnerOfBothTeams = false,
  divid
}) => {
  const { selectedGame, user: authUser, authenticated } = useContext(AppContext);
  let date = new Date(match.datetime);
  const { datetime, csgoData, lolData } = match;
  const league = match.league ? match.league : match.scrim ? match.scrim : match.tournament;
  const history = useHistory();
  const [time, setTime] = useState();
  const [isLive, setIsLive] = useState();
  const twitch = match?.league?.twitch || match?.tournament?.liveStreamLink;
  const [gameData, setGameData] = useState(!!league ? (league.game?.shortName == "CSGO" ? csgoData : lolData) : {});
  const [pillClass, setPillClass] = useState("success test");
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [setPlayersDeadline, setSetPlayersDeadline] = useState(360); // league.setPlayersDeadline will be 6 hours in mins when league not exist for tournaments
  const [expandedMatch, setExpandedMatch] = useState(false);
  const [showTournCodes, setShowTournCodes] = useState(false);
  const [players, setPlayers] = useState([]);
  const [showT1Tooltip, setShowT1Tooltip] = useState(false);
  const [showT2Tooltip, setShowT2Tooltip] = useState(false);
  const [isPreviousMatch, setIsPreviousMatch] = useState(moment(new Date()).valueOf() > moment(date).valueOf() ? true : false);
  const [datestime,setDatestime]=useState()
  useEffect(()=>{
    if(datetime){
      setDatestime(datetime)
    }
    if(date){

      setDatestime(date)

    }
  },[])
  const [isScrim, setIsScrim] = useState(page == "SCRIM_PAGE");
  let _players_not_set_matches_display_translation = useTranslated("id_match_display_13");
  let _checked_in_matches_display_translation = useTranslated("id_match_display_14");
  let _match_played_matches_display_translation = useTranslated("id_match_display_15");
  let _get_codes_matches_display_translation = useTranslated("id_match_display_19");

  //if players once set, then show already setted players while set players again.
  if (league?.game?.shortName !== "CSGO" && players.length) {
    if (match.lolData?.players[teamSelector]) match.lolData.players[teamSelector] = players;
    else if (match.csgoData?.players[teamSelector]) match.csgoData.players[teamSelector] = players;
  }
  useEffect(() => {
    const hoursDiffrence = moment(datestime).subtract(league?.setPlayersDeadline, "minutes").diff(moment(), "hours");
    let pillClass = "success 3";
    hoursDiffrence < 24
      ? (pillClass = `danger`)
      : hoursDiffrence > 24 && hoursDiffrence < 336
      ? (pillClass = `warning`)
      : (pillClass = `secondary 2`);

    // Have players been set?
    const isChecked = gameData?.players && (gameData.players[teamSelector] || []).length;
    setIsCheckedIn(isChecked ? true : false);
    pillClass = isChecked ? "success 3" : pillClass;
    setPillClass(pillClass);
  }, [datestime, isCheckedIn, league]);

  useEffect(() => {
    (league && league.setPlayersDeadline && setSetPlayersDeadline(league.setPlayersDeadline)) ||
      (league && league.checkInPeriod && setSetPlayersDeadline(scrimCheckinToMins(league.checkInPeriod)));
  }, [league]);

  // Decide whether setting players is allowed...
  const isCaptainOrCoach = () => {
    const { members: teamMembers = [], organisation } = team;
    const userMember = teamMembers.find(
      ({ user, coach }) =>
        (user && user._id === authUser.state._id) ||
        (coach && typeof coach.user !== "object" && coach.user === authUser.state._id) ||
        (coach && typeof coach.user == "object" && coach.user._id === authUser.state._id)
    );
    if (userMember?.isCaptain || userMember?.coach) {
      return true;
    }
    return false;
  };
  const showSetPlayers = () => {
    // As of requirements of setPlayers Owner,Manager,coaches and Captain can setPlayers Point 8 Team captain
    const { members: teamMembers = [], organisation } = team;
    const isOwner =
      team &&
      authenticated &&
      (teamMembers.find(
        ({ user: userData, role }) => userData && authUser.state._id === userData._id && ["Owner", "Manager"].includes(role)
      ) ||
        (organisation && organisation?.owners.find(({ user: userData }) => userData && userData._id === authUser.state._id)) ||
        authUser.state.admin);
    const isManager =
      team &&
      authenticated &&
      (teamMembers.find(({ user: userData, role }) => userData && authUser.state._id === userData._id && ["Manager"].includes(role)) ||
        authUser.state.admin);

    const _isCaptainOrCoach = isCaptainOrCoach();
    if (isOwner || isManager || _isCaptainOrCoach) {
      return true;
    }
    return false;
  };
  // const showSetPlayers = () => {
  //   const { members: teamMembers = [], organisation } = team;
  //   let isOrgOwner = false;
  //   let isTeamMember = false;

  //   if (organisation?.owners?.find(({ user }) => user === authUser.state._id)) {
  //     isOrgOwner = true;
  //   }

  //   if (
  //     teamMembers.find(
  //       ({ user, coach, player }) => (user && user._id === authUser.state._id) || (coach && coach.user === authUser.state._id)
  //     )
  //   ) {
  //     isTeamMember = true;
  //   }
  //   if (isWithinDeadline && (isOrgOwner || isTeamMember)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // Deadline is set in minutes and can't set players more than 2 weeks out
  const isWithinDeadline =
    moment(datestime).diff(moment(), "minutes") >= setPlayersDeadline && moment(datestime).diff(moment(), "hours") < 336;

  // If match is happening within next 24hrs
  const isSetPlayersUrgent =
    page === pages.YOUR_MATCHES &&
    match.status !== "complete" &&
    showSetPlayers() &&
    moment(datestime).diff(moment(), "hours") > -3 &&
    moment(datestime).diff(moment(), "hours") < 24 &&
    gameData.players &&
    !(gameData.players[teamSelector] || []).length;

  useEffect(() => {
    setIsLive(isMatchLive(match.datestime, match.bestOf));
  }, []);

  //for your-matches and for team's matches score pill color based on diff scenerios
  const getColorOfScorePill = () => {
    if (userTeams.includes(match.t1?._id) && userTeams.includes(match.t2?._id) && page === pages.YOUR_MATCHES) return;
    if (match.t1?._id === queryId || userTeams.includes(match.t1?._id)) {
      if (match.t1Score > match.t2Score) return "#22db60";
      else if (match.t1Score < match.t2Score) return "#fd1049";
      else return "#d5901f";
    } else if (match.t2?._id === queryId || userTeams.includes(match.t2?._id)) {
      if (match.t1Score < match.t2Score) return "#22db60";
      else if (match.t1Score > match.t2Score) return "#fd1049";
      else return "#d5901f";
    }
  };
  const removeZeroFromHrs = (hours) => {
    if (hours[0] == "0") return hours[1];
    return hours;
  };
  let matchDate = applyBrowserTimezone(date);

  let zone = matchDate ? matchDate.zoneAbbr() : null;
  const renderTeam1Name = () => (
    <div
      className={`mb-1 text-14 bold uppercase color-white text-wrap`}
      style={{
        color: match.t1Score > match.t2Score ? "var(--primary)" : null,
        opacity: match.status !== "complete" && page === pages.YOUR_MATCHES && teamSelector === "t2" ? 0.5 : 1,
      }}
      onMouseEnter={(e) => {
        if (e.target.offsetWidth < e.target.scrollWidth) {
          setShowT1Tooltip(true);
        }
      }}
    >
      {match.t1?.name}
    </div>
  );
  const renderTeam2Name = () => (
    <div
      className={`mb-1 text-14 bold uppercase color-white text-wrap`}
      style={{
        color: match.t1Score < match.t2Score ? "var(--primary)" : null,
        opacity: match.status !== "complete" && page === pages.YOUR_MATCHES && teamSelector === "t1" ? 0.5 : 1,
      }}
      onMouseEnter={(e) => {
        if (e.target.offsetWidth < e.target.scrollWidth) {
          setShowT2Tooltip(true);
        }
      }}
    >
      {match.t2?.name}
    </div>
  );

  return (
    <>
      <div
        className={`main-item ${"justify-content-between"}`}
        style={{ marginLeft: page ? "2.5rem" : "1.5rem", textDecoration: "none" }}
        id={divid?(divid):(page ? mainItemId : id)}
        // onClick={() => history.push(`/match/${id}`)}

        onMouseEnter={() => {
          onHover(index, groupIndex, isOwnerOfBothTeams, teamSelector, divid);
          // console.log(divid)
        }}
        onMouseLeave={() => {
          onLeave(index, groupIndex, isOwnerOfBothTeams, teamSelector,divid);
        }}
      >
        <Link
          id={page ? mainItemId : id}
          to={`/match/${id}`}
          className="linking"
          style={{ display: "content", marginLeft: page ? "2.5rem" : "1.5rem", textDecoration: "none" }}
        >
          <div className={`${isScrim ? "col-2" : "col-2"} row d-flex link-div`} style={{maxWidth:"25%" , flex: "0 0 25%",padding:"0px", marginLeft:"0px"}}>
            <div style={{ display: "inline-flex" }}>
              <span className="hours pl-1">{date ? removeZeroFromHrs(matchDate.format("HH")) : null}</span>
            </div>
            <div className="min-zone">
              <span className="mins">{date ? matchDate.format("mm") : null}</span>
              <span style={{ color: "#68717e" }} className="below-text">
                {zone ? (zone.includes("+") || zone.includes("-") ? `UTC${zone}` : zone) : null}
              </span>
            </div>
            {match.broadcasted === true && (
              <Link
              id={page ? mainItemId : id}
              to={`/match/${id}`}
                onClick={(e) => e.stopPropagation()}
                className={`${!twitch || twitch === "" ? "inactiveLink" : ""}`}
                style={{ textDecoration: "none" }}
              >
                <div className={(isLive ? "live" : "broad") + " live-broad uppercase bold d-flex row align-items-baseline"}>
                  <div className="dot" style={{ backgroundColor: isLive ? "#f11857" : "#8fa3b1" }}></div>
                  <div style={{ fontSize: "10px", marginRight: "5px" }}>{isLive ? "live" : "Broadcasted"}</div>
                </div>
              </Link>
            )}
          </div>

          <div
            className={`${
              page !== pages.YOUR_MATCHES && page !== pages.ACTIVE_NEXT_MATCH && !isScrim ? "col-5" : isScrim ? "col-4 p-0 m-0" : "col-4"
            } d-flex row-custom align-items-center link-div`}
            style={{maxWidth:"50%",    flex: "0 0 50%",marginLeft:"-35px"}}
          >
            <div
              className={`${fullScreen && "col-5"} col-4 px-0`}
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "1.2rem",
                opacity: match.status !== "complete" && page === pages.YOUR_MATCHES && teamSelector === "t2" ? 0.5 : 1,
              }}
            >
              <Link
                className="text-decoration-none"
                style={{ pointerEvents: !match.t1 ? "none" : "all", display: "contents" }}
                to={match.t1 && !match.t1?.placeHolder && `/teams/${match.t1._id}`}
                // to={`/match/${id}`}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="align-items-center" style={{ display: "flex", marginRight: "1.2rem" }}>
                  <div style={{ justifyContent: `flex-end`, fontSize: "10px" }}>
                    <div style={{ textAlign: "end" }}>
                      {match.t1 ? (
                        <>
                          <Tippy disabled={!showT1Tooltip} content={match.t1?.name} placement="right" animation="shift-toward">
                            {renderTeam1Name()}
                          </Tippy>

                          {match.teamStats && Object.keys(match.teamStats).length !== 0 && (
                            <>
                              <div className="mb-1 text-12 faded bold uppercase place">
                                {ordinalSuffixOf(match.teamStats?.t1?.pos)} place
                              </div>
                              <div className="text-12 faded bold" style={{ color:"#68717E" }}>
                                
                                {match.teamStats?.t1?.wins}W -{match.teamStats?.t1?.draws > 0 ? ` ${match.teamStats?.t1?.draws}T - ` : " "}
                                {match.teamStats?.t1?.losses}L
                              
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <span className="text-14 bold uppercase color-white">{match.sp1 ? `Seed ${match.sp1.seedPosition}` : 'DELETED'}</span>
                      )}
                    </div>
                  </div>
                </div>
                <span className="imgLogoContainer">
                  <img
                    className={` imgLogoWidth ${fullScreen && "imgLogo"}  ${
                      match.t1?.logoCropped || match.t1?.logoCropped === undefined ? "imgLogoCropped" : ""
                    }`}
                    src={match.t1?.logo?(match.t1?.logo):(imglogo)}
                    alt={`${match.t1?.name}`}
                  />
                </span>
              </Link>
            </div>
            <div style={{ minWidth: "70px" }}>
              {match.status === "complete" ? (
                <div
                  className="pill large primary m-0 row d-flex"
                  style={{
                    lineHeight: "15px",
                    width: "70px",
                    justifyContent: "center",
                    backgroundColor:
                      (page === pages.YOUR_MATCHES || page === pages.TEAM_MATCHES || page === pages.RECENT_MATCHES) &&
                      getColorOfScorePill(),
                  }}
                >
                  {match.t1Score} - {match.t2Score}
                </div>
              ) : (
                <div className="bold text-14 vs">VS</div>
              )}
            </div>
            <div
              className={`${fullScreen && "col-5"} col-4 px-0 `}
              style={{
                display: "flex",
                marginLeft: "1.2rem",
                opacity: match.status !== "complete" && page === pages.YOUR_MATCHES && teamSelector === "t1" ? 0.5 : 1,
              }}
            >
              <Link
                className="text-decoration-none "
                style={{ pointerEvents: !match.t2 ? "none" : "all", display: "contents" }}
                to={match.t2 && !match.t2?.placeHolder && `/teams/${match.t2._id}`}
                // to={`/match/${id}`}
                onClick={(e) => e.stopPropagation()}
              >
                <span className="imgLogoContainer">
                  <img
                    className={`imgLogoWidth ${fullScreen && "imgLogo"} ${
                      match.t2?.logoCropped || match.t2?.logoCropped === undefined ? "imgLogoCropped" : ""
                    }`}
                    src={match.t2?.logo?(match.t2?.logo):(imglogo)}
                    alt={`${match.t2?.name}`}
                  />
                </span>
                <div className="align-items-center " style={{ display: "flex", marginLeft: "1.2rem" }}>
                  <div style={{ justifyContent: `flex-start` }}>
                    <div>
                      {match.t2 ? (
                        <>
                          <Tippy content={match.t2?.name} disabled={!showT2Tooltip} placement="right" animation="shift-toward">
                            {renderTeam2Name()}
                          </Tippy>

                          {match.teamStats && Object.keys(match.teamStats).length !== 0 && (
                            <>
                              <div className="mb-1 text-12 faded bold uppercase place">
                                {ordinalSuffixOf(match.teamStats?.t2?.pos)} place
                              </div>

                              <div className="text-12 faded bold" style={{ color:"#68717E" }}>
                            
                                {match.teamStats?.t2?.wins}W -{match.teamStats?.t2?.draws > 0 ? ` ${match.teamStats?.t2?.draws}T - ` : " "}
                                {match.teamStats?.t2?.losses}L
                              
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <span className="text-14 bold uppercase color-white">{match.sp2 ? `Seed ${match.sp2.seedPosition}` : 'DELETED'}</span>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
         {stagesLength && stage.groups.length > 1 ? 
          <div className=" col-3 align-items-center" style={{ display: "flex", flexDirection:"column" ,flex:"0 0 12%" ,maxWidth:"12%" }}>
            { match?.groups?(match?.groups?.map(group => (
              <div className="par-num align-items-center uppercase" style={{background: `${group.color}22`    , fontSize: "12px",
              color: "white",
              fontWeight: "bold",
              borderRadius: "30px",
              textTransform: "uppercase",
              marginRight: "10px",
              width: "auto",
              textAlign: "center",
              cursor: "default",
              justifyContent: "center",
              padding: "7px 10px",
              display: "flex",
              margin:"3px",

              }}>
                <span style={{  color:group?.color 
                ,   textOverflow: "ellipsis",
                maxWidth: "9ch",
                whiteSpace: "nowrap",
                /* width: 400px; */
                overflow: "hidden"
                }}>{group.name}</span>
              </div>
            ))):(null)}
          </div>
          : (
            <div className=" col-3 align-items-center" style={{ display: "flex", flexDirection:"column" ,flex:"0 0 12%" ,maxWidth:"12%" }}>
            <div className="par-num align-items-center uppercase" style={{
              background: "inherit"    ,
               fontSize: "12px",
            color: "white",
            fontWeight: "bold",
            borderRadius: "30px",
            textTransform: "uppercase",
            marginRight: "10px",
            width: "auto",
            textAlign: "center",
            cursor: "default",
            justifyContent: "center",
            padding: "7px 10px",
            display: "flex,",
            margin:"3px",
            }}>
              <span style={{  color:"inherit"}}>{}</span>
            </div>
            </div>
          )}
          {!isScrim &&  (
            <div className="col-2 d-flex row justify-content-end league-best link-div" style={{ flex:"0 0 13%" ,maxWidth:"13%" }}>
             { stagesLength > 1 ? 
               <>
               <span className="text-14 bold uppercase color-white mb-1" style={{ flexDirection:"column" }}>
                   <b>{stage?.stageName}</b>
               </span>
               <span className="mb-1 text-12 faded bold uppercase place" style={{ color:"#8FA3B1", fontWeight: "200px", flexDirection:"column"}}>
                   <b>Round {match.round}</b>
               </span>
               <span className="text-12 faded bold" style={{ color:"#68717E" }}>
                 {!match.scrim && match.scrim !== null ? (
                   <div>BEST OF {match?.bestOf}</div>
                 ) : (
                   <b>
                     {match?.bestOf} GAME{match?.bestOf > 1 && "S"}
                   </b>
                 )}
               </span>
               </>
              :
              <>
              <span className="text-14 bold uppercase color-white mb-1" style={{ flexDirection:"column" }}>
                    <b>Round {match.round}</b>
              </span>
              <span className="mb-1 text-12 faded bold uppercase place" style={{ color:"#8FA3B1", fontWeight: "200px", flexDirection:"column"}}>
                  {!match.scrim && match.scrim !== null ? (
                    <div>BEST OF {match?.bestOf}</div>
                  ) : (
                    <b>
                      {match?.bestOf} GAME{match?.bestOf > 1 && "S"}
                    </b>
                  )}
              </span>
              </>
                 
             }
            </div>
          )}
        </Link>

        {(page === pages.YOUR_MATCHES || page == "SCRIM_PAGE" || page === pages.ACTIVE_NEXT_MATCH) && (
          <>
            <div className={`col-2 pr-0 pl-3 ${isScrim ? "col-3 p-0 m-0" : ""}`} style={{ textAlign: "center" }}>
              {match.status === "complete" ? (
                <span className="pill default ml-0">{_match_played_matches_display_translation}</span>
              ) : isCheckedIn ? (
                <span className={`pill  ${pillClass} ml-0`}>{_checked_in_matches_display_translation}</span>
              ) : (
                <span className={`pill ${pillClass} ml-0`}>{_players_not_set_matches_display_translation}</span>
              )}
            </div>
            <div
              className={`col-2 ${isScrim ? " p-0 m-0 scrimMatch" : ""} ${
                pages.YOUR_MATCHES === page || page === pages.ACTIVE_NEXT_MATCH ? " pr-0 ml-0 pl-3 code-align" : ""
              }`}
            >
              <div className="your-matches-sortable-list-actions">
                {isWithinDeadline || ((isScrim || page === pages.ACTIVE_NEXT_MATCH) && canSetPlayers) ? (
                  <Tippy
                    content="You don’t have permission to set players"
                    disabled={showSetPlayers() || ((isScrim || page === pages.ACTIVE_NEXT_MATCH) && canSetPlayers)}
                  >
                    <span>
                      <button
                        onClick={(e) => {
                          setExpandedMatch(
                            expandedMatch !== id + team?._id
                              ? id + team?._id
                              : (isScrim || page === pages.ACTIVE_NEXT_MATCH) && canSetPlayers && !expandedMatch
                              ? true
                              : false
                          );
                          e.stopPropagation();
                        }}
                        className={`button-link primary-link text-12 ${
                          !(showSetPlayers() || ((isScrim || page === pages.ACTIVE_NEXT_MATCH) && canSetPlayers))
                            ? "set_players_button-disabled"
                            : ""
                        }`}
                        disabled={!(showSetPlayers() || ((isScrim || page === pages.ACTIVE_NEXT_MATCH) && canSetPlayers))}
                      >
                        <>
                          {id + team?._id === expandedMatch ? (
                            <SetPlayersUp className="arrow-icon mr-2" />
                          ) : (
                            <SetPlayersDown className="arrow-icon mr-2" />
                          )}
                          <span>Set players</span>
                        </>
                      </button>
                    </span>
                  </Tippy>
                ) : !isWithinDeadline && _.isEmpty(gameData?.tournamentCodes ?? []) && match.status !== "complete" && !isPreviousMatch ? (
                  <Tippy
                    content="The match is too far into the future"
                    disabled={showSetPlayers() || ((isScrim || page === pages.ACTIVE_NEXT_MATCH) && canSetPlayers)}
                  >
                    <span>
                      <button
                        onClick={(e) => {
                          setExpandedMatch(
                            expandedMatch !== id + team?._id
                              ? id + team?._id
                              : (isScrim || page === pages.ACTIVE_NEXT_MATCH) && canSetPlayers && !expandedMatch
                              ? true
                              : false
                          );
                          e.stopPropagation();
                        }}
                        className={`button-link primary-link text-12 ${
                          !(showSetPlayers() || ((isScrim || page === pages.ACTIVE_NEXT_MATCH) && canSetPlayers))
                            ? "set_players_button-disabled"
                            : ""
                        }`}
                        disabled={!(showSetPlayers() || ((isScrim || page === pages.ACTIVE_NEXT_MATCH) && canSetPlayers))}
                      >
                        <>
                          {id + team?._id === expandedMatch ? (
                            <SetPlayersUp className="arrow-icon mr-2" />
                          ) : (
                            <SetPlayersDown className="arrow-icon mr-2" />
                          )}
                          <span>Set players</span>
                        </>
                      </button>
                    </span>
                  </Tippy>
                ) : (
                  !_.isEmpty(gameData?.tournamentCodes ?? []) &&
                  match.status !== "complete" && (
                    <button
                      className="button-link primary-link text-12 code-btn"
                      onClick={(e) => {
                        setShowTournCodes(true);
                        e.stopPropagation();
                      }}
                    >
                      {_get_codes_matches_display_translation}
                    </button>
                  )
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {(page === pages.YOUR_MATCHES || (isScrim && canSetPlayers) || (page === pages.ACTIVE_NEXT_MATCH && canSetPlayers)) && (
        <div className={expandedMatch ? "sortable-expandable-content show" : "sortable-expandable-content"}>
          {page === pages.YOUR_MATCHES && showSetPlayers() ? (
            <SetPlayers
              league={league}
              {...match}
              setPlayersDeadline={setPlayersDeadline}
              teamData={team}
              teamSelector={teamSelector}
              isSetPlayersUrgent={isSetPlayersUrgent}
              updateMatch={(uMatch) => {
                if (setPlayers) {
                  setPlayers(
                    match.t1.game.shortName !== "CSGO" ? uMatch.lolData.players[teamSelector] : uMatch.csgoData.players[teamSelector]
                  );
                }
                let _gameData = league.game?.shortName == "CSGO" ? uMatch.csgoData : uMatch.lolData;
                setGameData(_gameData);
                const isChecked = _gameData.players && (_gameData.players[teamSelector] || []).length;
                setIsCheckedIn(isChecked ? true : false);
                setExpandedMatch(false);
              }}
            />
          ) : (
            <>{renderSetPlayers}</>
          )}
        </div>
      )}

      <TempTournCodes
        isOpen={showTournCodes}
        onClose={() => setShowTournCodes(false)}
        team1={match?.t1?.name}
        team2={match?.t2?.name}
        teamSelector={teamSelector}
        tournamentCodes={gameData?.tournamentCodes || []}
      />
    </>
  );
};

export default ScheduleMatchesItem;
const pages = {
  LEAGUE: "LEAGUE",
  TOURNAMENT: "TOURNAMENT",
  TEAM_MATCHES: "TEAM_MATCHES",
  YOUR_MATCHES: "YOUR_MATCHES",
  RECENT_MATCHES: "RECENT_MATCHES",
  ACTIVE_NEXT_MATCH: "ACTIVE_NEXT_MATCH",
};
