import React from "react";

const Icon = ({ name, without_wrapper, style, pureIcon = false, classes = "" ,iconClass=""}) => {
  if (typeof without_wrapper !== "undefined") {
    return <img src={require(`@img/${name}.svg`)} alt="Icon" />;
  }

  if (pureIcon) {
    return (
      <span className={"icon primaryColor " + classes} style={style}>
        {pureIcon}
      </span>
    );
  }

  return (
    <span className={"icon "+ iconClass} style={style}>
      <img src={require(`@img/${name}.svg`)} alt="Icon" style={style} />
    </span>
  );
};

export default Icon;
