import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import default_image from "@img/image.jpg";
import GlitchClip from "react-glitch-effect/core/Clip";
import { getGameBackground } from "../../../utils/additional-helpers";

const ItemTimeAgo = ({ time_ago }) => {
  return (
    <div className="item-info">
      <span>{time_ago}</span>
    </div>
  );
};

const ItemImageInner = ({ tag }) => {
  return (
    <React.Fragment>
      <div className="image-cover"></div>
      <span>{tag}</span>
      <div className="bottom-image-cover"></div>
    </React.Fragment>
  );
};

const AboveExcerpt = ({ content }) => {
  if (typeof content === "undefined") {
    return null;
  }

  return <div className="item-above-excerpt pl-2">{content}</div>;
};

const Item = (props) => {
  let { glitchDisabled = false } = props;
  let classes = "item gs-gc-box ";
  let imageContainerClass = "";

  if (typeof props.imageContainerClass !== "undefined") {
    imageContainerClass = props.imageContainerClass;
  }

  if (typeof props.large !== "undefined") {
    classes += " large";
  }

  if (typeof props.column !== "undefined") {
    classes += "frontpage-item-collumn col-" + props.column;
  }

  if (typeof props.classes !== "undefined") {
    classes += " " + props.classes;
  }

  if (typeof props.loading !== "undefined") {
    classes += " loading";
  }
  const openInNewWindow = props.openInNewWindow ?? false;
  const title = props.title ?? "[Deleted]";
  const excerpt = props.excerpt;
  const image = props.image ?? props.default_image ?? default_image;
  const timeAgo = moment(props.published ?? new Date()).fromNow();
  const link_to = props.link_to ?? "#";
  const hideTimeAgo = props.hide_time_ago ?? false;
  const above_excerpt = props.above_excerpt;
  const tag = props.tag;

  return (
    <div onClick={props.onClick} className={classes}>
      <Link 
        target={openInNewWindow && "_blank"} 
        to={link_to} style={{ 
          textDecoration: "none"
        }}>
        <span class="gs-gc-top" ></span>
        <span class="gs-gc-bottom" ></span>
        <span class="gs-gc-left" ></span>
        <span class="gs-gc-right" ></span>
        <span class="gs-gc-right-corner" ></span>
        <span class="gs-gc-left-corner" ></span>
        <div className="item-title frontpage-title headingGameCard pl-2">
          <h3 style={{ fontSize: "17px", paddingTop: "19px" }}>{title}</h3>
        </div>
        <div className="item-title frontpage-title gotogameButton">
          <Link target={openInNewWindow && "_blank"} to={link_to} style={{ textDecoration: "none" }}>
            <h3 style={{ fontSize: "17px" }}>GO TO GAME</h3>
          </Link>
        </div>

        <GlitchClip disabled={glitchDisabled} duration="10s" onHover={glitchDisabled ? false : true}>
          <div
            className={`item-image frontpage-i-image ${imageContainerClass}`}
            style={{ backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${image})` }}
          >
            {props.item_image_inner ?? <ItemImageInner tag={tag} />}
            <div className="coverOpacity"> </div>
          </div>

          {!hideTimeAgo ? <ItemTimeAgo time_ago={timeAgo} /> : null}

          <AboveExcerpt content={above_excerpt} />
          { excerpt && <div className="item-excerpt pl-2 pb-2">{excerpt}</div> }
          {/* <div className="gotogameButton">GOTO GAME</div> */}
        </GlitchClip>
        {/*  */}
      </Link>
    </div>
  );
};

export default Item;
