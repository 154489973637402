import React from "react";

const RegionTag = ({region}) => {
    return(
        <span className="region-badge">
            {region.code}
        </span>
    )
}


export default RegionTag