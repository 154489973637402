import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { WithAppContext } from "./context/AppContext";
import AppRouter from "./AppRouter";
import "./styles/dynamiccss.css";
import "./app.scss";
import Layout from "./components/Layout/Layout";
import Axios from "axios";
import verifyToken from "./context/utils/verifyToken";
import { FiX } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { getTranslationData } from "./redux/action/action";
import { setLanguage } from "./redux/action/setLanguageAction";
const App = () => {
  let root = document.documentElement;
  const selectedGameSelected = localStorage.getItem(["selectedGame"]);
  if (selectedGameSelected && JSON.parse(selectedGameSelected).mainColour) {
    root.style.setProperty("--primary", JSON.parse(selectedGameSelected).mainColour);
  }
  const dispatch = useDispatch()
useEffect(()=>{
  getTrans()
},[])
  const getTrans = () => {

    dispatch(getTranslationData())
  }
  // useEffect(()=>{
  //  const lang = localStorage.getItem('selectedLanguage') 
  //  if(!lang){
  //   let obj = {
  //     _id: 1,
  //     originalname: "English",
  //     name: "English",
  //     img: "https://cmp-edge-arena-cdn2.azureedge.net/20220824.24/Content/img/flags/df9b30d811cfdb62c81f18f74ced37dd.svg",
  //   }
  //   localStorage.setItem('selectedLanguage',JSON.stringify(obj))
  //   dispatch(setLanguage(obj))
  //  }else{
  //   dispatch(setLanguage(JSON.parse(lang)))
  //  }
  // })
  // const [bannerContent, setBannerContent] = useState(null);
  // const [needPlayerSetting, setNeedPlayerSetting] = useState(null);

  // const hideBannerBars = (type) => {
  //   if (type === "banner_content") {
  //     setBannerContent(null);
  //   } else {
  //     setNeedPlayerSetting(null);
  //   }
//commit
  //   localStorage.setItem([type], "hide");
  // };

  // const showBannerBars = (type) => {
  //   const bannerStatus = localStorage.getItem([type]);
  //   // console.log(type,'-',bannerStatus)

  //   if (bannerStatus === null || (bannerStatus && bannerStatus !== "hide") || needPlayerSetting) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // useEffect(() => {
  //   const bannerStatus = localStorage.getItem(["banner_content"]);
  //   if (bannerStatus === null || (bannerStatus && bannerStatus !== "hide")) {
  //     // setBannerContent(
  //     //   "Leagues.gg is still under development. <a target='_blank' href='https://leagues.gg/news/stillunder-contruction'>Follow the process here.</a>"
  //     // );
  //   }
  // }, [setBannerContent]);

  // useEffect(() => {
  //   const validTokenData = verifyToken();
  //   if (validTokenData) {
  //     Axios.get(`${process.env.REACT_APP_CORE_API}/api/matches/needToSetPlayersSoon`)
  //       .then(({ data }) => {
  //         // console.log("tato", data);
  //         if (data) {
  //           setNeedPlayerSetting("Less than a day left to set players. Do it here. <a href='/your-matches'>Go to your matches.</a>");
  //         } else {
  //           hideBannerBars("warning_content");
  //         }
  //       })
  //       .catch(() => {
  //         // toast.error("There was a problem retrieving your matches");
  //       });
  //   }
  // }, []);

  return (
    <div
    // className={
    //   (showBannerBars("banner_content") || showBannerBars("warning_content")) && (bannerContent !== null || needPlayerSetting !== null)
    //     ? "App has_banner_content"
    //     : "App"
    // }
    >
      {/* {showBannerBars("banner_content") && bannerContent !== null ? (
        <div className="header-banner-bar">
          <span className="text-12 bold uppercase" dangerouslySetInnerHTML={{ __html: bannerContent }}></span>
          <span
            className="text-12 bold uppercase"
            style={{ position: "absolute", right: "25px" }}
            onClick={() => hideBannerBars("banner_content")}
          >
            <FiX style={{ fontWeight: "bold", fontSize: "27px" }} />
          </span>
        </div>
      ) : null}

      {showBannerBars("warning_content") && needPlayerSetting !== null ? (
        <div className="header-banner-bar warningBar">
          <span className="text-12 bold uppercase" dangerouslySetInnerHTML={{ __html: needPlayerSetting }}></span>
          <span
            className="text-12 bold uppercase"
            style={{ position: "absolute", right: "25px" }}
            onClick={() => hideBannerBars("warning_content")}
          >
            <FiX style={{ fontWeight: "bold", fontSize: "27px" }} />
          </span>
        </div>
      ) : null} */}

      <BrowserRouter>
        <WithAppContext>
          <Layout>
            <AppRouter />
          </Layout>
        </WithAppContext>
      </BrowserRouter>
    </div>
  );
};

export default App;
