import React, { useState } from "react";

import ActiveForNextMatch from "./includes/ActiveForNextMatch";
import AllMembers from "./includes/AllMembers";
import useTranslated from "../../../../../helpers/translationHelper";

const Members = ({ team, isOwner, isManager,isCoach,setTeamCaptain}) => {
  const [page, setPage] = useState(0);

  const lableAll=useTranslated("id_team_page_(members)_0")
  const lableActivefor=useTranslated("id_team_page_(members)_1")

  return (
    <React.Fragment>
      <div className="tabs-container mb-4">
        <div className="tabs-nav" style={{ borderBottom: "none" }}>
          <nav>
            <button className={page === 0 ? "active" : ""} onClick={() => setPage(0)}>
              {lableAll/* All */}
            </button>
            <button className={page === 1 ? "active" : ""} onClick={() => setPage(1)}>
              {lableActivefor/* Active for the next match */}
            </button>
          </nav>
        </div>
      </div>

      {page === 0 && <AllMembers team={team} isOwner={isOwner} isManager={isManager} isCoach={isCoach} setTeamCaptain={setTeamCaptain}/>}
      {page === 1 && <ActiveForNextMatch team={team} isOwner={isOwner} isManager={isManager}/>}
    </React.Fragment>
  );
};

export default Members;
