import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";


const LanguageList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  height: 260px;
  width: 100%;

  &::-webkit-scrollbar {
    display: block;
  }
`
const LanguageListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  font-size: 0.8rem;
  border-right: 1px solid #22262E;

  &:hover {
    background-color: rgb(28, 32, 41);
  }
`

const LanguageLabel = styled.span`
  display: flex;
  align-items: center;
`

const LanguageEndonym = styled.span`
  font-family: Montserrat;
  font-weight: normal;
`

const Flag = styled.img`
  display: block;
  box-sizing: content-box;
  height: 1rem;
  width: auto;
  padding: 5px 10px 5px 5px;
`

const List = ({ input, selectLanguage }) => {
  const language = useSelector((state) => state.LANGUAGE.Selected);
  let allLanguages = useSelector((state) => state.LANGUAGE.AllLanguages);
  allLanguages= allLanguages.sort((a,b) => (a.language > b.language) ? 1 : ((b.language > a.language) ? -1 : 0))

  const SelectedValue = (language) => {
    selectLanguage(language);
  };

  const filteredData = allLanguages.filter((el) => {
    if (input === "") {
      return el;
    } else {
      return el.language.toLowerCase().includes(input);
    }
  });

  const firstCharUppercase = (language) => language && language.charAt(0).toUpperCase() + language.slice(1);
  return (
    <LanguageList>
      {filteredData.map((Language, index) =>
      Language.isavailable? (
        <LanguageListItem
          isSameLanguage={language._id === Language._id}
          id={`lang-${Language._id}`}
          key={Language._id}
          onClick={() => SelectedValue(Language)}
          className="itemshover"
        >
          <LanguageLabel>
            <Flag src={`https://www.flagcdn.com/128x96/${Language.flag.toLowerCase()}.png`} alt="not found"/>
            <div>{firstCharUppercase(Language.language)} {" (" + Language.langCode?.toUpperCase() + ")"}</div>

          </LanguageLabel>
          <LanguageEndonym>
            {firstCharUppercase(Language.endonym)}
          </LanguageEndonym>
        </LanguageListItem>
      ):(null))}
    </LanguageList>
  );
};

export default List;
