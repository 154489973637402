import React, { useState } from "react";
import Modal from "react-modal";
import { RiSearchLine, RiCloseLine } from "react-icons/ri";
import DynamicSearch from "../../_common/DynamicSearch/DynamicSearch";

const ConnectOrganization = ({open, onClose, onChange, onClick, loading, results, clearResult}) => {
    const [selection, setSelection] = useState(null)

    return (
        <Modal isOpen={open} onRequestClose={() => {setSelection(null); onClose()}} className="modal" shouldCloseOnEsc={false} style={{height:'475px'}}>
            <div className="modal-close" onClick={() => {setSelection(null); onClose()}}>
                <RiCloseLine />
            </div>
            <div className="modal-header">
                <h2>ADD TO AN ORGANISATION</h2>
                <h3>Structure your team by adding them to your organisation</h3>
            </div>
            <div className="modal-body">
                <div className='dynamic-search-input o-c'>
                    {/* <input type={'text'} className='form-input' placeholder="Search for an organisation" onChange={(e) => onChange(e.target.value)}/> */}
                    <DynamicSearch loadingResults={loading} results={results} placeholder={'Search for an organisation'} clearResult={clearResult} onSearch={onChange} onSelectResult={setSelection}/>
                    {/* <RiSearchLine className="input-icon" /> */}
                </div>
                {!selection && (
                <div className="organization-contaier" style={{height:'100px',marginTop:'41px'}}>
                    <span>ORGANISATION</span>
                </div>
                )}
                {selection && (
                    <div className="container pl-0 pr-0">
                        <h6 className="mb-e-0" style={{marginTop:'25px',marginBottom:'8px', fontSize:'15px',textTransform:'uppercase'}}>{selection.title}</h6>
                        <img src={selection.logoCropped} className='img-fluid' style={{height:'100px'}}/>
                    </div>
                )}
                <button style={{ backgroundColor: selection ? "#3eabf0" : "rgba(62, 171, 240, 0.5)" }} disabled={selection ? false : true} className="button secondary thicc mt-40" onClick={() => {onClick(selection); setSelection(null)}}>JOIN ORGANISATION</button>
            </div>
        </Modal>
    )
}
export default ConnectOrganization