import React, { useContext, useEffect, useState, useRef } from "react";
import { ScheduleMatchesItem, Icon, Loading, Checkbox } from "..";
import { Icon as FoldedIcon } from "@common";
import { AppContext } from "../../../context/AppContext";
import { BsChevronUp, BsArrowUp, BsChevronDown } from "react-icons/bs";
import { getCurrentIsoTime, groupMatchesDate} from "@utils";
import Axios from "axios";
import { isCustomDateMatch } from "../../../utils/time-functions";
// For Dropdown
import styled from "styled-components";
import MatchesSwitchItem from "../../MultiStageLeague/MatchesSwitchItem";
import { Row } from "antd";
import { FiX, FiChevronUp, FiChevronDown } from "react-icons/fi";
// import { BsChevronUp, BsChevronDown } from "react-icons/bs";

import "./switch.css";
import { isConcatSpreadable } from "core-js/fn/symbol";

import { useSelector } from "react-redux";
import "./switch.css";
import useTranslated from "../../../helpers/translationHelper";
const MultiStageLeagueMatchListingByDate = (props) => {
  let {
    page,
    id: queryId,
    fullScreen = false,
    existingMatches,
    isRecent,
    showSetPlayers,
    canSetPlayers,
    renderSetPlayers,
    isActive,
    teamSelector,
  } = props; //queryId is "id" belongs to league or tournament or team or user
  let TotalStages = 0;
  let translation = useSelector(state=> state.TRANSLATION ? state.TRANSLATION.Translation : [])
  const { selectedGame } = useContext(AppContext);

  const [pageTitle, sepageTitle] = useState("Match");
  const [upPage, setUpPage] = useState(0);
  const [downPage, setDownPage] = useState(0);
  const [isPrev, setIsPrev] = useState(false); //if API call is for previous matches from current date
  const [matches, setMatches] = useState([]); //matches after group by date
  const [orignalMatches, setOrignalMatches] = useState(isRecent ? existingMatches : []); //matches from API
  const [dataInfoDown, setDataInfoDown] = useState();
  const [dataInfoUp, setDataInfoUp] = useState();
  const [scroll, setScroll] = useState(false);
  const [prevResultDate, setPrevResultDate] = useState();
  const [prevResultButton, setPrevResultButton] = useState();
  const [currentResultDate, setCurrentResultDate] = useState();
  const [currentMatches, setcurrentMatches] = useState([]); //current date matches so go to today button can be show accordingly
  const matchesScroll = useRef(null);
  const [showToday, setShowToday] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isTodayPressed, setIsTodayPressed] = useState(false);
  const [firstAPICall, setFirstAPICall] = useState(true);
  const [selected, setSelected] = useState([0, 1, 2, 3, 4]); //for tournament & league matches
  const [teams, setTeams] = useState([]); //for your-matches
  const [clickedPastResult, setClickPastResult] = useState(false);
  const [firstPrev, setFirstPrev] = useState(false);
  const [MatchDiv, setMatchDiv] = useState();
  const [multiStageLeagueMatches, setMultiStageLeagueMatches] = useState(false);
  const [filteredStageMatches, setFilteredStageMatches] = useState(false);
  const [multiStageGroups, setMultiStageGroups] = useState([]);
  const [multiStageGroupMatches, setMultiStageGroupMatches] = useState([]);
  const [matchesCount, setMatchesCount] = useState(0);
  const [allSelectedGroups, setAllSelectedGroups] = useState([]);
  const [filteredMatch, setFilteredMatch] = useState([]);
  const [selectedGroupTag, setSelectedGroupTag] = useState([]);
  const [groupsExist, setGroupsExist] = useState(false);
  let showmatch = true;
  // const [groupsCheckDisabled, setGroupsCheckedDisabled] = useState(false);
  let copiddate = null;
  const [langTranslation, setlangTranslation] = useState(false);
  // const [groupsCheckDisabled, setGroupsCheckedDisabled] = useState(false);
  const { user } = useContext(AppContext);
  // For Dropdown
  let arr = [];
  let divcount = 0;
  const dropdown = useRef();
  const [showDropdown, setShowDropdown] = useState(false);
  useEffect(() => {
    const trans = translation
    trans.forEach(el=>{
      const elem = document.getElementById(el.id)
      if(elem){
        elem.innerHTML = el.translat
      }
    })
  },[translation,loading])
  const Januarymonth=useTranslated('id_utility_-_months_1')
  const Februarymonth=useTranslated('id_utility_-_months_2')
  const Marchmonth=useTranslated('id_utility_-_months_3')
  const Aprilmonth=useTranslated('id_utility_-_months_4')
  const Maymonth=useTranslated('id_utility_-_months_5')
  const Junemonth=useTranslated('id_utility_-_months_6')
  const Julymonth=useTranslated('id_utility_-_months_7')
  const Augustmonth=useTranslated('id_utility_-_months_8')
  const Septembermonth=useTranslated('id_utility_-_months_9')
  const Octobermonth=useTranslated('id_utility_-_months_10')
  const Novembermonth=useTranslated('id_utility_-_months_11')
  const Decembermonth=useTranslated('id_utility_-_months_12')
  
  const Monday=useTranslated('id_utility_-_week_days_0')
  const Tuesday=useTranslated('id_utility_-_week_days_1')
  const Wednesday=useTranslated('id_utility_-_week_days_2')
  const Thursday=useTranslated('id_utility_-_week_days_3')
  const Friday=useTranslated('id_utility_-_week_days_4')
  const Saturday=useTranslated('id_utility_-_week_days_5')
  const Sunday=useTranslated('id_utility_-_week_days_6')


  let _today_matches_display_translation = useTranslated("id_match_display_9");
  let _past_results_matches_display_translation = useTranslated("id_match_display_10");



  const getMatchGroupDate = (date) => {
    const tempDate = new Date(date);
    let months = [Januarymonth, Februarymonth, Marchmonth, Aprilmonth, Maymonth, Junemonth, Julymonth, Augustmonth, Septembermonth, Octobermonth, Novembermonth, Decembermonth];
    let weekdays = [Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday];
    let groupDate = {};
    groupDate.day = weekdays[tempDate.getDay()];
    groupDate.month = months[tempDate.getMonth()];
    groupDate.date = tempDate.getDate();
    groupDate.year = tempDate.getFullYear();
  
    return groupDate;
  };
  const stageMatchCount = (stages) => {
    let count = 0;
    stages.forEach((stage) => {
      stage.groups.forEach((group) => {
        count += group.matches.length;
      });
    });
    setMatchesCount(count);
  };

  const checkOutsideClick = (e) => {
    // if click outside div
    if (dropdown.current && !dropdown.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", checkOutsideClick);
  }, []);

  useEffect(() => {
    if ((scroll || firstAPICall) && !isRecent) {
      setLoading(true);
      setFirstAPICall(false);
      let scrollTemp = scroll;
      setScroll(false);
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${queryId}/matches`).then(async ({ data }) => {
        setMultiStageLeagueMatches(data.data.allStageMatches);
        setFilteredStageMatches([...data.data.allStageMatches]);
        let groupsCheck = false;
        data.data.allStageMatches.forEach((stage) => {
          if (stage.groups.length > 1) groupsCheck = true;
        });
        setGroupsExist(groupsCheck);
        stageMatchCount(data.data.allStageMatches);
        let tempArray = [];
        data.data.allStageMatches.map((value, index) => {
          tempArray.push(index);
          setSelected(tempArray);
        });
        setLoading(false);
      });
    }
  }, [scroll]);
  const getUniqueMatches = (arr) =>{ // added by shariq to filtering unique matches
    return arr.filter((value, index) => {
      const _value = JSON.stringify(value);
      return index === arr.findIndex(obj => {
        return JSON.stringify(obj) === _value;
      });
    })
  }
  const getSortedMatches = (arr) => {  //added by shariq to sort matches 
    let allMatches = arr
      .map((stage) => {
        return stage.groups.map((group, g_index) => {
          if (allSelectedGroups?.length > 0 ? allSelectedGroups.includes(group._id) : true) {
            return group?.matches;
          }
        });
      })
      .flat(2);
    let uniqueMatches = getUniqueMatches(allMatches);
    let filtered = uniqueMatches.filter((el) => el !== undefined);
    return filtered.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
  };;
  const onHover = (index, groupIndex, isOwnerOfBothTeams, teamSelector, divid) => {
    if (divid) {
      var b2 = document.getElementById(`${divid}`);

      var b = document.getElementById(`${divid}`).nextElementSibling;
      if (b.id === divid) {
        let text = document.getElementById(`${divid}`).lastChild;
        text.id = "newdiv";
        let text2 = document.getElementById(`newdiv`).lastChild;
        text2.id = "newdiv2";
        text2.classList.remove("listing-item-hover");
        text2.classList.add("listing-item-hover");
        b2.style.marginRight = "-20px";
      } else {
        var b = document.getElementById(`${divid}`).previousElementSibling;
        b.classList.remove("listing-item-hover");
        b.classList.add("listing-item-hover");
      }
    }
  };

  const onLeave = (index, groupIndex, isOwnerOfBothTeams, teamSelector, divid) => {
    if (divid) {
      var b = document.getElementById(`${divid}`).nextElementSibling;
      if (b.id === divid) {
        var b2 = document.getElementById(`${divid}`);
        b2.style.marginRight = "20px";
        let text = document.getElementById(`${divid}`).lastChild;
        text.id = "newdiv";
        let text2 = document.getElementById(`newdiv`)?.lastChild;
        text2.id = "newdiv2";
        text2.classList.remove("listing-item-hover");
        text2.id = "";
        text.id = "";
      } else {
        var b3 = document.getElementById(`${divid}`).previousElementSibling;
        b3.classList.remove("listing-item-hover");
      }
    }
  };

  const _setSelected = (value) => {
    // console.log(value);

    if (selected.includes(value)) {
      let count = 0;
      selected.forEach(v => (v || v === 0) && count++);
      if (count === 1) return;
      else {
        selected[value] = null;
        let filteredStageMatchesCopy = [...multiStageLeagueMatches];
        let stageArray = [];
        selected.forEach((val) => {
          if (val || val === 0) {
            stageArray.push(filteredStageMatchesCopy[val]);
          }
        });
        let groupIds = multiStageLeagueMatches[value].groups.map(group => group._id);
        let allSelectedGroupsCopy = [...allSelectedGroups];
        setAllSelectedGroups(allSelectedGroupsCopy.filter(val => !groupIds.includes(val.toString())));
        let selectedGroupTagCopy = [...selectedGroupTag];
        selectedGroupTagCopy = selectedGroupTagCopy.filter(val => !groupIds.includes(val._id.toString()));
        setSelectedGroupTag(selectedGroupTagCopy)
        setFilteredStageMatches(stageArray);
        stageMatchCount(stageArray);
      }
    }else {
      selected[value] = value;
      let stageArray = [];
      multiStageLeagueMatches.forEach((stage, stageIndex) => {
        if (selected.includes(stageIndex)) stageArray.push(stage);
      });
      setFilteredStageMatches(stageArray);
      stageMatchCount(stageArray);
    }
    setSelected([...selected]);
  };
  const selectedGroupSwitch = (id, group) => {
    // For Group Switch
    let allSelectedGroupsCopy = [...allSelectedGroups];
    if (allSelectedGroups.includes(id)) {
      allSelectedGroupsCopy.splice(
        allSelectedGroups.findIndex((el) => el.toString() === id.toString()),
        1
      );
    } else {
      allSelectedGroupsCopy.push(id);
    }
    setAllSelectedGroups(allSelectedGroupsCopy);
    // For Group Tag
    let selectedGroupTagCopy = [...selectedGroupTag];
    if (selectedGroupTagCopy.includes(group)) {
      selectedGroupTagCopy.splice(
        selectedGroupTag.findIndex((el) => el === group),
        1
      );
    } else {
      selectedGroupTagCopy.push(group);
    }
    setSelectedGroupTag(selectedGroupTagCopy);
  };
  return loading ? (
    <>
      <div className="text-center league-no-matches">
        <Loading width={50} height={50} />
      </div>
    </>
  ) : (
    <div style={{ display: "flex", flexDirection: "column", maxWidth: "100%", overflow: "hidden" }}>
      <div className="col-12 p-0">
        <div className="align-items-center justify-content-between" style={{ display: "flex", flexDirection: "row", marginTop: "-1px" }}>
          {multiStageLeagueMatches.length && (
            <>
              <div
                className=""
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "80%", marginLeft: "2.5rem" }}
              >
                {!isRecent && (
                  <div className="text-uppercase bold" style={{alignSelf:"center"}}>
                    {matchesCount} {pageTitle}
                  </div>
                )}

                {multiStageLeagueMatches?.length > 1 || multiStageLeagueMatches?.groups?.length > 1 ? (
                  <div className="row p-0 m-0 align-items-center mr-3">
                    <span className="bold text-grey uppercase font-13 mr-2">Show:</span>
                    {multiStageLeagueMatches.map((stage, stageIndex) => (
                      <Checkbox
                        key={stageIndex}
                        type="checkbox"
                        checked={selected === [] ? true : selected.includes(stageIndex) ? true : false}
                        onChange={() => _setSelected(stageIndex)}
                        label={stage.stageName}
                        className="check-type1"
                        game={selectedGame.name}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
              {/* DROPDOWN START */}

              <div style={{ width: "20%", position: "absolute", marginTop: "-38px", marginLeft: "calc(83% + 1px)" }}>
                {filteredStageMatches && (
                  <Wrapper className="" style={{ position: "absolute" }}>
                    <div
                      ref={dropdown}
                      className={`dropdown-wrapper dynamic-width header-game-switcher gc-box toggle-game header-game-switcher ${
                        showDropdown ? "show" : ""
                      }`}
                    >
                      <span
                        className={`dropdown-toggle ${showDropdown ? "dropdown-toggle1" : ""}`}
                        onClick={(e) => {
                          if (TotalStages <= 1) {
                            setShowDropdown(false);
                          } else {
                          //   groupsExist && 
                            setShowDropdown(!showDropdown);
                          }
                        }}
                      >
                        <div style={{ width: "124%" }}>
                          <span className="label">FILTER BY GROUP</span>
                        </div>
                        <span className="caret">
                          <span className="icon">
                            {showDropdown ? (
                              <BsChevronUp style={{ marginTop: 5, marginRight: "-8px" }} />
                            ) : (
                              <BsChevronDown style={{ marginTop: 5, marginRight: "-8px" }} />
                            )}
                          </span>
                        </span>
                      </span>
                      <span className="gc-top-content" />
                      <div
                        className={`dropdown-content edge`}
                        style={{ margin: "0px 0px 5px 10px !important", backgroundColor: "rgb(25, 27, 36)" }}>
                        <main className="scroll-gradient gradients">
                          {filteredStageMatches &&
                            filteredStageMatches.map((stage) => {
                              // if(selected.includes(stageIndex)) {
                              if (stage?.groups?.length) {
                                // document.getElementsByClassName('dropdown-toggle')[1].style.backgroundColor = '#03060d';

                                return stage.groups.map((group, groupIndex) => (
                                  <>
                                    <Row key={group._id} style={{ flexDirection: "row", display: "flex", paddingBottom: 10 }}>
                                      {filteredStageMatches ? (
                                        <MatchesSwitchItem
                                          isMultiSelect={true}
                                          onSwitch={(e) => {
                                            selectedGroupSwitch(group._id, group);
                                          }}
                                          checked={allSelectedGroups.includes(group._id.toString())}
                                        />
                                      ) : null}
                                      <div style={{ flexDirection: "column", display: "flex" }}>
                                        {filteredStageMatches?.length ? (
                                          <span className="active-text uppercase" style={{      textOverflow: "ellipsis",
                                          maxWidth: "12ch",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          }}>{group.groupName}</span>
                                        ) : (
                                          <span className="active-text uppercase bold"  style={{      textOverflow: "ellipsis",
                                          maxWidth: "12ch",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          }}>
                                            {group.groupName}
                                          </span>
                                        )}

                                        <span
                                          className="active-text uppercase bold"
                                          style={{ color: "#8FA3B1", fontSize: 10, fontWeight: "bold", fontFamily: "Montserrat",
                                          textOverflow: "ellipsis",
                                          maxWidth: "12ch",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                        }}
                                        >
                                          {filteredStageMatches?.length === 1 ? "" : stage.stageName}
                                        </span>
                                      </div>
                                    </Row>
                                  </>
                                ));
                              }
                              else{
                                document.querySelectorAll(".dropdown-toggle").disabled=true;
                                document.querySelectorAll(".dropdown-toggle1").disabled=true;
                              }
                            })}
                        </main>
                      </div>
                      <span className="gc-bottom-content" />

                      {filteredStageMatches &&
                        filteredStageMatches.map((stage) => {
                          TotalStages = stage?.groups?.length + TotalStages;
                          if (TotalStages <= 1) {
                            // document.getElementsByClassName('dropdown-toggle')[1].style.background = '#191b24';
                            if (document.getElementsByClassName("dropdown-toggle")[1]) {
                              document.getElementsByClassName("dropdown-toggle")[1].classList.add("dropdown-toggle3");
                            }
                            return (
                              <>
                                <span className="gc-top gc-top1" />
                                <span className="gc-bottom gc-bottom1" />
                                <span className="gc-left gc-left1" />
                                <span className="gc-right gc-right1" />
                                <span className="gc-right-corner gc-right-corner1" />
                                <span className="gc-left-corner gc-left-corner1" />
                              </>
                            );
                          } else {
                            if (document.getElementsByClassName("dropdown-toggle")[1]) {
                              document.getElementsByClassName("dropdown-toggle")[1].classList.remove("dropdown-toggle3");
                            }
                            return (
                              <>
                                <span className="gc-top " />
                                <span className="gc-bottom " />
                                <span className="gc-left " />
                                <span className="gc-right " />
                                <span className="gc-right-corner "></span>
                                <span className="gc-left-corner" />
                              </>
                            );
                          }
                        })}
                    </div>

                    {/* //end here */}
                  </Wrapper>
                )}
              </div>
              {/* DROPDOWN END */}
            </>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "1.5rem", marginLeft: "2.5rem" }}>
          {selectedGroupTag.length > 0
            ? selectedGroupTag.map((groupTag, groupTagIndex) => (
                <div
                  key={groupTag._id}
                  className="par-num align-items-centerd uppercase"
                  style={{
                    borderRadius: 20,
                    background: groupTag ? `${groupTag.color}22` : false,
                    justifyContent: "center",
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    marginRight: "5px",
                  }}
                >
                  <span
                    key={groupTag._id}
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      color: groupTag ? groupTag.color : false,
                      padding: " 10px 0px 10px 15px",
                      alignItems: "center",
                      textOverflow: "ellipsis",
                      maxWidth: "12ch",
                      whiteSpace: "nowrap",
                      /* width: 400px; */
                      overflow: "hidden"
                    }}
                  >
                    {groupTag ? groupTag.groupName : false}
                  </span>
                  <FiX
                    className="close"
                    onClick={(e) => selectedGroupSwitch(groupTag._id, groupTag)}
                    style={{
                      fontSize: "0.9rem",
                      color: groupTag ? groupTag.color : false,
                      fontWeight: 500,
                      cursor: "pointer",
                    }}
                  />
                </div>
              ))
            : false}
        </div>
      </div>
      <div className="col-12 p-0" style={{ paddingLeft: "30px", paddingRight: "30px", overflowY: "scroll", maxHeight: "700px" }}>
        {
          <>
            {filteredStageMatches && getSortedMatches(filteredStageMatches)
            
                    // .map((match, matchIndex) => {
                    //   if (selected.every((element) => element !== null)) {
                    //     let exists = arr.filter((x) => x.matchid === match._id && x.datet === match.datetime);
                    //     if (exists.length <= 0) {
                    //       arr.push({ matchid: match._id, datet: match.datetime });
                    //       showmatch = true;
                    //     } else {
                    //       showmatch = false;
                    //     }
                    //   }
                    .map((match,matchIndex)=>{
                         let date = getMatchGroupDate(match["datetime"]);
                      let currentDate = new Date().getFullYear();
                      let groupDate = new Date(Object.keys(match.datetime)[0]).getFullYear();
                      if (showmatch === true) {
                        showmatch = true;
                        divcount = divcount + 1;
                        return (
                          <>
                            {copiddate !== JSON.stringify(date) && (
                              <div
                                id={`Match_div${matchIndex}${divcount}`}
                                className={`grayboxListing graybox_yourmatches_hover ${page === pages.RECENT_MATCHES && "recentMatches"} ${
                                  page === pages.ACTIVE_NEXT_MATCH && "activeMatch"
                                }`}
                                ref={(r) => (matchesScroll.current = r)}
                                style={{ overflowY: "hidden", position: "sticky", top: "0", zIndex: "10", background: "#03060d" }}
                              >
                                <div style={{ display: "none" }}>{(copiddate = JSON.stringify(date))}</div>
                                {loading && isPrev && (
                                  <div className="col-12 text-center">
                                    <Loading />
                                  </div>
                                )}
                                <div className="date-wrapper">
                                  <div
                                    className=" row d-flex justify-content-between"
                                    id={Object.keys(match)[0]}
                                    style={{ marginLeft: "4.4rem", height: "58px" , transition: "all 0.25s ease-out"}}
                                  >
                                    <span className="uppercase" style={{ marginTop: "38px" }}>
                                      <b>{date.day}</b> - {date.date} {date.month} {groupDate != currentDate ? date.year : null}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      borderBottom: "1px solid #22262e",
                                      lineHeight: "5.5em",
                                      marginLeft: "4.4rem",
                                      transition: "background-color .25s ease-out,width .25s ease-out,margin .25s ease-out,padding .25s ease-out",
                                      "-ms-transition": "background-color .25s ease-out,width .25s ease-out",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            )}
                            <ScheduleMatchesItem
                              key={match?._id + (match.t1?._id || match.t2?._id || "")}
                              divid={`Match_div${matchIndex}${divcount}`}
                              match={match}
                              stagesLength={multiStageLeagueMatches.length}
                              stageGroup={match.groups[0]}
                              id={match?._id}
                              date={date}
                              fullScreen={fullScreen}
                              page={page}
                              queryId={queryId}
                              userTeams={teams}
                              stage={filteredStageMatches.find(stage=> stage._id === match.groups[0].stage)}
                              showSetPlayers={showSetPlayers}
                              canSetPlayers={canSetPlayers}
                              onHover={onHover}
                              onLeave={onLeave}
                              index={matchIndex}
                              groupIndex={match.groups[0]._id}
                              renderSetPlayers={renderSetPlayers}
                              teamSelector={teamSelector}
                              mainItemId={match?._id + (match.t1?._id || match.t2?._id || "")}
                            />
                          </>
                          )
                        }
                    })
                    //   let date = getMatchGroupDate(match["datetime"]);
                    //   let currentDate = new Date().getFullYear();
                    //   let groupDate = new Date(Object.keys(match.datetime)[0]).getFullYear();
                    //   if (showmatch === true) {
                    //     showmatch = true;
                    //     divcount = divcount + 1;
                    //     return (
                    //       <>
                    //         {copiddate !== JSON.stringify(date) && (
                    //           <div
                    //             id={`Match_div${matchIndex}${divcount}`}
                    //             className={`grayboxListing graybox_yourmatches_hover ${page === pages.RECENT_MATCHES && "recentMatches"} ${
                    //               page === pages.ACTIVE_NEXT_MATCH && "activeMatch"
                    //             }`}
                    //             ref={(r) => (matchesScroll.current = r)}
                    //             style={{ overflowY: "hidden", position: "sticky", top: "0", zIndex: "10", background: "#03060d" }}
                    //           >
                    //             <div style={{ display: "none" }}>{(copiddate = JSON.stringify(date))}</div>
                    //             {loading && isPrev && (
                    //               <div className="col-12 text-center">
                    //                 <Loading />
                    //               </div>
                    //             )}
                    //             <div className="date-wrapper">
                    //               <div
                    //                 className=" row d-flex justify-content-between"
                    //                 id={Object.keys(match)[0]}
                    //                 style={{ marginLeft: "4.4rem", height: "58px" , transition: "all 0.25s ease-out"}}
                    //               >
                    //                 <span className="uppercase" style={{ marginTop: "38px" }}>
                    //                   <b>{date.day}</b> - {date.date} {date.month} {groupDate != currentDate ? date.year : null}
                    //                 </span>
                    //               </div>
                    //               <div
                    //                 style={{
                    //                   borderBottom: "1px solid #22262e",
                    //                   lineHeight: "5.5em",
                    //                   marginLeft: "4.4rem",
                    //                   transition: "background-color .25s ease-out,width .25s ease-out,margin .25s ease-out,padding .25s ease-out",
                    //                   "-ms-transition": "background-color .25s ease-out,width .25s ease-out",
                    //                 }}
                    //               ></div>
                    //             </div>
                    //           </div>
                    //         )}
                    //         <ScheduleMatchesItem
                    //           key={match?._id + (match.t1?._id || match.t2?._id || "")}
                    //           divid={`Match_div${matchIndex}${divcount}`}
                    //           match={match}
                    //           stagesLength={multiStageLeagueMatches.length}
                    //           stageGroup={group}
                    //           id={match?._id}
                    //           date={date}
                    //           fullScreen={fullScreen}
                    //           page={page}
                    //           queryId={queryId}
                    //           userTeams={teams}
                    //           stage={stage}
                    //           showSetPlayers={showSetPlayers}
                    //           canSetPlayers={canSetPlayers}
                    //           onHover={onHover}
                    //           onLeave={onLeave}
                    //           index={matchIndex}
                    //           groupIndex={g_index}
                    //           renderSetPlayers={renderSetPlayers}
                    //           teamSelector={teamSelector}
                    //           mainItemId={match?._id + (match.t1?._id || match.t2?._id || "")}
                    //         />
                    //       </>
                    //     );
                    //   }
                    // });
                    // arr=[];
                  // }
              //   });
              // })
              }
            <span class="no-match-text-matches" id="end">
              No future matches scheduled
            </span>
          </>
        }
      </div>
    </div>
  );
};

export default MultiStageLeagueMatchListingByDate;

const Wrapper = styled.div`
.dflex{
  display: flex !important;

}.dropdown-wrapper.show .dropdown-toggle {
  background-color: rgb(25, 27, 36) !important;
  width: 195px !important;
  margin-left: -9px  !important;
  justify-content: center  !important;
}
.display-content {
  display: content !important;
}
.gradients {
width: inherit !important;
}
.dropdown-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .dropdown-dotted-button {
    position: relative;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    span {
      width: 4px;
      height: 4px;
      margin: 6px 2px;
      background-color: $text-faded-color;
      border-radius: 2px;
    }

    #dropdown-dotted-button-menu-box {
      display: none;
    }

    #dropdown-dotted-button-menu-box:checked + .dropdown-dotted-button-menu {
      display: block;
    }

    .dropdown-dotted-button-menu {
      position: absolute;
      right: -10px;
      top: 100%;
      width: 200px;
      padding: 12px;
      background-color: $header-bg-color;
      border: 1px solid $border-color;
      display: none;
      cursor: pointer;
      z-index: 9;

      div {
        margin-bottom: 12px;
        font-size: 12px;
        line-height: 15px;
        font-weight: normal;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  .dropdown-toggle3 {

    background-color: #191b24 !important;
    
    }
  .gc-top1{
    border-color:gray !important;
    background: gray !important;
    background-color: gray !important;
  }
  .gc-bottom1{
    border-color:gray !important;

    background: gray !important;
    background-color: gray !important;
  }
  .gc-left1{
    border-color:gray !important;

    background: gray !important;
    background-color: gray !important;
  }
  .gc-right1{
    border-color:gray !important;

    background: gray !important;
    background-color: gray !important;
  }
  .gc-right-corner1{
    border-color:gray !important;

    background: gray !important;
    background-color: gray !important;
  }
  .gc-left-corner1{
    border-color:gray !important;

    background: gray !important;
    background-color: gray !important;
  }
  
  .dropdown-toggle {
    position: relative;
    padding: 10px 12px;
    cursor: pointer;

    .gc-ddown {
      padding: 6px 12px;
    }
    .image {
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      background-size: cover;
      margin-right: 10px;
    }

    // .icon img {
    //   vertical-align: middle;
    // }

    span.label {
      // font-size: 0.8rem;
      font-size: 12px;
      font-weight: bold;
    }

    .badge {
      position: absolute;
      background-color: var(--primary);
      padding: 2px 4px;
      border-radius: 2px;
      font-size: 8px;
      font-weight: bold;
      line-height: 10px;
      color: $white;
      top: 0px;
      right: 0px;
      transform: translate(-30%, 40%);

      &:empty {
        display: none;
      }
    }

    .caret {
      margin-left: 10px;

      .icon img {
        width: 8px;
        opacity: 0.5;
      }
    }
    :hover {
      background-color: #191b24;
    }
  }

  .dropdown-content {
    position: absolute;
    top: 53%; //////
    width: 230px;
    background-color: $header-bg-color;
    border-radius: 2px;
    border-top-right-radius: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-transition: height 0.05s linear;
    transition: height 0.05s linear;
    height: 0;
    opacity: 0;
    margin: 0px 0px 0px -10px !important;
  }
  .dropdown-content-animation {
    -webkit-transition: height 0.05s linear;
    transition: height 0.05s linear;
    height: 0;
    opacity: 0;
  }

  // .bottom-image-cover {
  //   position: absolute;
  //   transform: rotate(45deg);
  //   right: -160px;
  //   bottom: -160px;
  //   width: 200px;
  //   height: 200px;
  //   background-color: $content-bg-color;

  //   &.forGameSelector {
  //     background-color: transparent;
  //   }
  // }

  &.show {
    .dropdown-content {
      // display: block;
      height: auto;
      opacity: 1;

      .right-corner-dropdown {
        position: absolute;
        background-color: var(--primary);
        height: 28px;
        width: 1px;
        bottom: 7px;
        right: 16px;
        z-index: 1000;
        transform: rotate(47deg);
      }
    }
    .edge {
      // polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0)
      // clip-path: polygon(0% 0, 100% 0, 100% 88%, 92% 100%, 0 100%, 0% 50%);
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 19px), calc(100% - 20px) 100%, 0 100%, 0 0);
    }
    .dropdown-toggle {
      background-color: $header-bg-color; //$dropdown-bg;
      border-radius: 2px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      z-index: 40;
    }
    .z_index {
      z-index: 39;
    }
  }

  &.dynamic-width {
    .dropdown-content {
      // width: auto;
      // max-width: 230px;
      // border: 1px solid var(--primary);
      // position: absolute;
      // border-top: none;
      // width: auto;
      // max-width: 196px;
      // z-index: 38;
      border: 0;
      position: relative;
      border-top: none;
      background-color: #191b24; !important;
      max-width: 193px;
      z-index: 38;
      top: 0px;
    }
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    border-bottom: 1px solid $border-color;

    h3 {
      font-size: 14px;
      line-height: 18px;
      margin: 0px;
      padding: 0px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  main {
    padding: 10px;

    .loaderForGamesSelection {
      margin: 13px 69px;
    }
  }
}

.gc-box {
  .dropdown-toggle {
    padding: 5px 22px;
    // margin-left: -17px;
    margin-left: -9px;
    clip-path: polygon(0% 0, 100% 0, 100% 49%, 91% 100%, 0 100%, 0% 50%);
    width: 115%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      width: 63%;
    }
    height: 37px;
  }
  .dropdown-toggle1 {
    clip-path: none !important;
  }
  height: auto;
  width: 170px;
  border-radius: 0px;
  z-index: 40px;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start;
  margin:auto;
  // position: absolute; 
  // :hover {
  //   background-color: #191b24;
  // }

  .gc-top,
  .gc-bottom,
  .gc-left,
  .gc-right,
  .gc-left-corner,
  .gc-right-corner {
    position: absolute;
    background-color: var(--primary);
  }

  .gc-top {
    width: calc(100% - -4.5px);
    height: 1px;
    top: 0;
    left: 9.3px;
    z-index: 50;

    border: 0px solid;
    border-color: var(--primary);
  }
  .gc-bottom-content {
    position: absolute;
    height: 21px;
    width: 11px;
    bottom: -8px;
    right: -15px;
    transform: rotate(45deg);
    z-index: 42;
  }

  .gc-top-content {
    position: absolute;
    height: 23px;
    width: 11px;
    top: -8px;
    left: -9px;
    background-color: #03060c;
    transform: rotate(45deg);
    z-index: 42;
  }

  .gc-bottom {
    width: calc(100% - -5px);
    height: 1px;
    bottom: -1.4px;
    right: 4px;
    z-index: 50;
    border: 0px solid;
    border-color: var(--primary);
  }

  .gc-left {
    height: calc(100% - 16.5px);
    width: 1px;
    bottom: -1px;
    left: -9px;
    z-index: 50;
    border: 0px solid;
    border-color: var(--primary);
  }

  .gc-right {
    height: calc(100% - 17.5px);
    width: 1px;
    top: 0px;
    right: -14px;
    z-index: 50;
    border: 0px solid;
    border-color: var(--primary);
  }

  .gc-left-corner {
    height: 25px;
    width: 1px;
    top: -3.5px;
    left: 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 50;
    border-width: 0px;
    border-style: solid;
    border-image: initial;
    border-color: var(--primary);
    height: 26px;
    width: -1px;
    top: -4.5px;
    left: 0px;
    -webkit-transform: rotate(48deg);
    -ms-transform: rotate(48deg;transform:rotate(49deg););
    transform: rotate(45deg);
    border-width: 0px;
    border-style: solid;
}

  }

  .gc-right-corner {
    height: 25.9px;
    width: 1px!important;
    bottom: -5px;
    right: -5px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(43.7deg);
    z-index: 50;
    border: 0px solid;
    border-color: var(--primary);
  }
}

.toggle-game {
  :hover {
    transition: background-color 0.4s ease-in-out;
  }
}
.empty {
  background-color: #0f1116;
  position: absolute;
  right: 0px;
  top: 15px;
  left: -9px;
  width: 194px;
  height: 15px;
  z-index: 36;
}
/* div::-webkit-scrollbar {
  display: block !important;
  width: 10px;
  z-index: 99;
}
div::-webkit-scrollbar-track {
  background-color: #191b24;
}
div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #22262e;
} */

.dropdown-content-div {
  border: 2px red;
  display: contents;
  clip-path: polygon(0% 0, 100% 0, 100% 83%, 85% 100%, 0 100%, 0% 50%);
  .right-corner-dropdown {
    position: absolute;
    background-color: var(--primary);
    height: 28px;
    width: 1px;
    bottom: 7px;
    right: 16px;
    z-index: 1000;
    transform: rotate(47deg);
  }
}
`;

const pages = {
  LEAGUE: "LEAGUE",
  TOURNAMENT: "TOURNAMENT",
  TEAM_MATCHES: "TEAM_MATCHES",
  YOUR_MATCHES: "YOUR_MATCHES",
  RECENT_MATCHES: "RECENT_MATCHES",
  ACTIVE_NEXT_MATCH: "ACTIVE_NEXT_MATCH",
};
const SELECTEDBRACKET = {
  WINNER: "WINNER",
  LOSER: "LOSER",
};
