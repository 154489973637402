import React from 'react';
import styled from 'styled-components';
import Selector from '../../../_common/Selector/Selector';
import DynamicSearch from '../../../_common/DynamicSearch/DynamicSearch';
import useTranslated from '../../../../helpers/translationHelper';

const Search = ({
    onSearch, 
    onClickSearchResult, 
    regionOptions, 
    selectedRegions, 
    setSelectedRegions, 
    labelHandlerRegions,
    typeOptions,
    typeCustomButton,
    selectedTypes,
    setSelectedTypes,
    labelHandlerTypes,
    loading, 
    categorizedResults}) => {


    return (
        <Wrapper>
            <SearchContainer className='searchContainer'>
                <StyledSearch className='styledsearch'
                    placeholder={useTranslated("id_top_bar_2")}
                    onSearch={onSearch}
                    onSelectResult={onClickSearchResult}
                    loadingResults={loading}
                    categorizedResults={categorizedResults}
                    isMainSearch={true}
                    mixedResults={true}
                    newTab>
                        <Selector 
                            multiple
                            badge
                            required
                            options={regionOptions}
                            value="code"
                            label="name"
                            selectedOptions={selectedRegions} 
                            setSelectedOptions={setSelectedRegions}
                            selectorLabelHandler={labelHandlerRegions}
                        />
                </StyledSearch>
                
            </SearchContainer>
            <Selector 
                    multiple
                    customButton={typeCustomButton}
                    options={typeOptions}
                    value="type"
                    selectedOptions={selectedTypes} 
                    setSelectedOptions={setSelectedTypes}
                    selectorLabelHandler={labelHandlerTypes}
                />
        </Wrapper>
    )
}


const Wrapper = styled.div`
    display: flex;
    width: 520px;
    height: 100%;
    position: relative;
`

const StyledSearch = styled(DynamicSearch)`
    display: inline-flex;
    width: 100%;
    height: 100%;
`

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    flex-grow: 1;
    border: 1px solid #2C2C2C;
    border-radius: 2px 0 0 2px;
    transition: border-color 0.2s ease-in-out;

    &:focus-within {
        border-color: var(--primary);
    }
`


export default Search