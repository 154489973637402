import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import SwitchItem from "./SwitchItem";
import { Row, Switch } from "antd";
import TriangleDown from "../../img/triangle-down.svg";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";

const MultiStageDropdown = ({
  groups,
  pages,
  page,
  isMultiSelect,
  selectedTags,
  onSeletedChange,
  setDefault,
  reUpdate,
  fromBrackets,
  isAll,
  setCustom,
}) => {
  const dropdown = useRef();
  const [showDropdown, setShowDropdown] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    document.addEventListener("mousedown", checkOutsideClick);
    let _arr = [];
    groups.map((item, i) => {
      if (setDefault && i === 0) {
        _arr.push({ groupId: item.group._id, status: true, name: item.group.name, color: item.group.color });
      } else if (setCustom) {
        _arr.push({ groupId: item.group._id, status: item.group.status, name: item.group.name, color: item.group.color });
      } else {
        _arr.push({ groupId: item.group._id, status: false, name: item.group.name, color: item.group.color });
      }
    });
    setData(_arr);
  }, []);

  useEffect(() => {
    if (reUpdate) {
      let _arr = [];
      groups.map((item, i) => {
        if (setDefault && i === 0) {
          _arr.push({ groupId: item.group._id, status: true, name: item.group.name, color: item.group.color });
        } else if (setCustom) {
          _arr.push({ groupId: item.group._id, status: item.group.status, name: item.group.name, color: item.group.color });
        } else {
          _arr.push({ groupId: item.group._id, status: false, name: item.group.name, color: item.group.color });
        }
      });
      setData(_arr);
    }
    if (fromBrackets) {
      let _arr = [];
      groups.map((item, i) => {
        if (data.length > 0) {
          let status = data.filter((x) => item.group._id === x.groupId);
          if (status.length > 0) {
            _arr.push({ groupId: item.group._id, status: status[0].status, name: item.group.name, color: item.group.color });
          } else {
            _arr.push({ groupId: item.group._id, status: i === 0 ? true : false, name: item.group.name, color: item.group.color });
          }
        }
      });
      if (_arr.length > 0) setData(_arr);
    }
    if (groups.length <= 1) {
      if (document.querySelectorAll("dropdown-toggle")[1]) {
        document.querySelectorAll("dropdown-toggle")[1].click(false);
      }
    }
  }, [groups]);

  const checkOutsideClick = (e) => {
    // if click outside div
    if (dropdown.current && !dropdown.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };
  const onChangeHandler = (value, groupId) => {
    let filtered = [...data];
    if (isMultiSelect) {
      filtered.map((x, i) => {
        if (x.groupId === groupId) {
          x.status = value;
        }
      })
    } 
    else {
      filtered.map((x, i) => {
        if (x.groupId === groupId) {
          x.status = true;
        } else {
          x.status = false;
        }
      });
    }
    setData(filtered);
    onSeletedChange(filtered);
  };

  return (
    <Wrapper>
      <div
        ref={dropdown}
        className={`dropdown-wrapper dynamic-width header-game-switcher gc-box toggle-game header-game-switcher ${
          showDropdown ? "show" : ""
        }`}
      >
        <span
          className={`${groups.length <= 1 ? " dropdown-toggle3" : "dropdown-toggle1"} dropdown-toggle ${
            showDropdown ? "dropdown-toggle1" : ""
          }`}
          onClick={(e) => {
            groups?.length >= 2 ? setShowDropdown(!showDropdown) : setShowDropdown(false);
          }}
        >
          <div style={{ width: "124%", marginTop: "1px" }}>
            <span className="label">FILTER BY GROUP</span>
          </div>
          <span className="caret"  >
            <span className="icon">
              {showDropdown ? (
                <BsChevronUp style={{ marginTop: 5, marginRight: "-8px" }} />
              ) : (
                <BsChevronDown style={{ marginTop: 5, marginRight: "-8px" }} />
              )}
            </span>
          </span>
        </span>
        <span className="gc-top-content" />
        <span className={`${showDropdown === false ? "gc-bottom-content" : "gc-bottom-content1"}`} />

        <div className={`dropdown-content  edge`} style={{ margin: "0px 0px 0px 10px !important", backgroundColor: "rgb(25, 27, 36)" }}>
          <main className="scroll-gradient gradients">
            {groups?.map((item, i) => (
              <Row className="dflex mb-2">
                <SwitchItem
                  onChangeHandler={onChangeHandler}
                  groupId={item?.group?._id}
                  checked={ (data?.length > 0 && data?.filter((x) => x.groupId === item?.group?._id)[0]?.status) ? true : false}
                />
                <div style={{ flexDirection: "column", display: "flex" }}>
                  <span
                    className="active-text uppercase bold"
                    style={{
                      textOverflow: "ellipsis",
                      maxWidth: "12ch",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {item?.group.name}
                  </span>
                  {isAll ? (
                    <span
                      className="active-text uppercase bold"
                      style={{
                        color: "#8FA3B1",
                        fontSize: 10,
                        fontWeight: "bold",
                        fontFamily: "Montserrat",
                        textOverflow: "ellipsis",
                        maxWidth: "12ch",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.stageName}
                    </span>
                  ) : (
                    <span className="active-text" style={{ color: "#8FA3B1", marginTop: 10, fontWeight: "bold", fontFamily: "Montserrat" }}>
                      {" "}
                    </span>
                  )}
                </div>
              </Row>
            ))}
          </main>
        </div>
        {groups?.length <= 1 ? (
          // document.getElementsByClassName('dropdown-toggle')[1].style.background = '#191b24';

          <>
            <span className="gc-top gc-top1" />
            <span className="gc-bottom gc-bottom1" />
            <span className="gc-left gc-left1" />
            <span className="gc-right gc-right1" />
            <span className="gc-right-corner gc-right-corner1" />
            <span className="gc-left-corner gc-left-corner1" />
          </>
        ) : (
          <>
            <span className="gc-top " />
            <span className="gc-bottom " />
            <span className="gc-left " />
            <span className="gc-right " />
            <span className="gc-right-corner "></span>
            <span className="gc-left-corner" />
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default MultiStageDropdown;

const Wrapper = styled.div`
.dropdown-toggle3 {
  background-color: rgb(25, 27, 36) !important;
}
  .dflex{
    display: flex !important;

  }.dropdown-wrapper.show .dropdown-toggle {
    background-color: rgb(25, 27, 36) !important;
    width: 193px !important;
    margin-left: -9px  !important;
    justify-content: center  !important;
  }
  .display-content {
    display: content !important;
  }
  .gradients {
  width: inherit !important;
}
.dropdown-toggle3 {
  background-color: rgb(25, 27, 36) !important; !important;
}
  .dropdown-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .dropdown-dotted-button {
      position: relative;
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      span {
        width: 4px;
        height: 4px;
        margin: 6px 2px;
        background-color: $text-faded-color;
        border-radius: 2px;
      }

      #dropdown-dotted-button-menu-box {
        display: none;
      }

      #dropdown-dotted-button-menu-box:checked + .dropdown-dotted-button-menu {
        display: block;
      }

      .dropdown-dotted-button-menu {
        position: absolute;
        right: -10px;
        top: 100%;
        width: 200px;
        padding: 12px;
        background-color: $header-bg-color;
        border: 1px solid $border-color;
        display: none;
        cursor: pointer;
        z-index: 9;

        div {
          margin-bottom: 12px;
          font-size: 12px;
          line-height: 15px;
          font-weight: normal;

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    .gc-top1{
      border-color:gray !important;
      background: gray !important;
      background-color: gray !important;
    }
    .gc-bottom1{
      border-color:gray !important;
  
      background: gray !important;
      background-color: gray !important;
    }
    .gc-left1{
      border-color:gray !important;
  
      background: gray !important;
      background-color: gray !important;
    }
    .gc-right1{
      border-color:gray !important;
  
      background: gray !important;
      background-color: gray !important;
    }
    .gc-right-corner1{
      border-color:gray !important;
  
      background: gray !important;
      background-color: gray !important;
    }
    .gc-left-corner1{
      border-color:gray !important;
  
      background: gray !important;
      background-color: gray !important;
    }
    .dropdown-toggle {
      position: relative;
      padding: 10px 12px;
      cursor: pointer;

      .gc-ddown {
        padding: 6px 12px;
      }
      .image {
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: middle;
        background-size: cover;
        margin-right: 10px;
      }

      // .icon img {
      //   vertical-align: middle;
      // }

      span.label {
        // font-size: 0.8rem;
        font-size: 12px;
        font-weight: bold;
      }
      
      .badge {
        position: absolute;
        background-color: var(--primary);
        padding: 2px 4px;
        border-radius: 2px;
        font-size: 8px;
        font-weight: bold;
        line-height: 10px;
        color: $white;
        top: 0px;
        right: 0px;
        transform: translate(-30%, 40%);

        &:empty {
          display: none;
        }
      }

      .caret {
        margin-left: 10px;

        .icon img {
          width: 8px;
          opacity: 0.5;
        }
      }
      :hover {
        background-color: #191b24;
      }
    }

    .dropdown-content {
      position: absolute;
      top: 53%; //////
      width: 230px;
      background-color: $header-bg-color;
      border-radius: 2px;
      border-top-right-radius: 0px;
      overflow-x: hidden;
      overflow-y: scroll;
      -webkit-transition: height 0.05s linear;
      transition: height 0.05s linear;
      height: 0;
      opacity: 0;
      margin: 0px 0px 0px -10px !important;
    }
    .dropdown-content-animation {
      -webkit-transition: height 0.05s linear;
      transition: height 0.05s linear;
      height: 0;
      opacity: 0;
    }

    // .bottom-image-cover {
    //   position: absolute;
    //   transform: rotate(45deg);
    //   right: -160px;
    //   bottom: -160px;
    //   width: 200px;
    //   height: 200px;
    //   background-color: $content-bg-color;

    //   &.forGameSelector {
    //     background-color: transparent;
    //   }
    // }

    &.show {
      .dropdown-content {
        // display: block;
        height: auto;
        opacity: 1;

        .right-corner-dropdown {
          position: absolute;
          background-color: var(--primary);
          height: 28px;
          width: 1px;
          bottom: 7px;
          right: 16px;
          z-index: 1000;
          transform: rotate(47deg);
        }
      }
      .edge {
        // polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0)
        // clip-path: polygon(0% 0, 100% 0, 100% 88%, 92% 100%, 0 100%, 0% 50%);
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 19px), calc(100% - 20px) 100%, 0 100%, 0 0);
      }
      .dropdown-toggle {
        background-color: $header-bg-color; //$dropdown-bg;
        border-radius: 2px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        z-index: 40;
      }
      .z_index {
        z-index: 39;
      }
    }

    &.dynamic-width {
      .dropdown-content {
        // width: auto;
        // max-width: 230px;
        // border: 1px solid var(--primary);
        // position: absolute;
        // border-top: none;
        // width: auto;
        // max-width: 196px;
        // z-index: 38;
        border: 0;
        position: relative;
        border-top: none;
        background-color: #191b24; !important;
        max-width: 195px;
        z-index: 38;
        top: 0px;
      }
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 12px;
      border-bottom: 1px solid $border-color;

      h3 {
        font-size: 14px;
        line-height: 18px;
        margin: 0px;
        padding: 0px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    main {
      padding: 10px;

      .loaderForGamesSelection {
        margin: 13px 69px;
      }
    }
  }

  .gc-box {
    .dropdown-toggle {
      padding: 5px 22px;
      // margin-left: -17px;
      margin-left: -9px;
      clip-path: polygon(0% 0, 100% 0, 100% 49%, 91% 100%, 0 100%, 0% 50%);
      width: 114%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        width: 63%;
      }
      height: 37.5px;
    }
    .dropdown-toggle1 {
      clip-path: none !important;
    }
    height: auto;
    width: 170px;
    border-radius: 0px;
    z-index: 40px;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start;
    position: absolute; 
    // :hover {
    //   background-color: #191b24;
    // }

    .gc-top,
    .gc-bottom,
    .gc-left,
    .gc-right,
    .gc-left-corner,
    .gc-right-corner {
      position: absolute;
      background-color: var(--primary);
    }

    .gc-top {
      width: calc(100% - -4.5px);
      height: 1px;
      top: 0;
      left: 9.3px;
      z-index: 50;
      border: 0px solid;
      border-color: var(--primary);
    }
    .gc-bottom-content {
      position: absolute;
      background-color: #03060d;
      height: 25px;
      width: 15px;
      bottom: -8px;
      right: -20px;
      transform: rotate(45deg);
      z-index: 42;
    }
    .gc-bottom-content1{
      position: absolute;
      background-color: transparent;
      height: 25px;
      width: 15px;
      bottom: -8px;
      right: -20px;
      transform: rotate(45deg);
      z-index: 42;
    }
    .gc-top-content {
      position: absolute;
      height: 23px;
      width: 11px;
      top: -8px;
      left: -9px;
      background-color: #03060c;
      transform: rotate(45deg);
      z-index: 42;
    }

    .gc-bottom {
      width: calc(100% - -5px);
      height: 1px;
      bottom: -1.4px;
      right: 4px;
      z-index: 50;
      border: 0.0px solid;
      border-color: var(--primary);
    }

    .gc-left {
      height: calc(100% - 16.5px);
      width: 1px;
      bottom: -1px;
      left: -9px;
      z-index: 50;
      border: 0.0px solid;
      border-color: var(--primary);
    }

    .gc-right {
      height: calc(100% - 17.5px);
      width: 1px;
      top: 0px;
      right: -14px;
      z-index: 50;
      border: 0.0px solid;
      border-color: var(--primary);
    }

    .gc-left-corner {
      height: 25px;
      width: 1px;
      top: -3.5px;
      left: 0px;
      transform: rotate(45deg);
      z-index: 50;
      border-width: 0px;
      border-style: solid;
      border-image: initial;
      border-color: var(--primary);
      height: 25px;
      width: -1px;
      top: -3.5px;
      left: 0px;
      transform: rotate(48deg);
      z-index: 50;
      border-width: 0px;
      border-style: solid;
  
    }

    .gc-right-corner {
      height: 25px;
    width: 1px;
    bottom: -4px;
    right: -5px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 50;
    border: 0px solid;
    border-color: var(--primary);
    height: 26.2px;
    width: 1p;
    bottom: -5.1px;
    right: -4.9px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg;transform:rotate(44.deg););
    -webkit-transform: rotate(deg);
    -ms-transform: rotate(deg);
    transform: rotate(deg);
    border: 0px solid;
    //   height: 25px;
    // width: 1px;
    // bottom: -4px;
    // right: -5px;
    // -webkit-transform: rotate(45deg);
    // -ms-transform: rotate(45deg);
    // transform: rotate(45deg);
    // z-index: 50;
    // border: 0px solid;
    // border-color: var(--primary);
    // height: 26.2px;
    // width: 1p;
    // bottom: -4.5px;
    // right: -4.4px;
    // x: ;
    // -webkit-transform: rotate(43.2deg);
    // -ms-transform: rotate(45deg;transform:rotate(44.deg););
    // transform: rotate(deg);
    // border: 0px solid;
    }
  }

  .toggle-game {
    :hover {
      transition: background-color 0.4s ease-in-out;
    }
  }
  .empty {
    background-color: #0f1116;
    position: absolute;
    right: 0px;
    top: 15px;
    left: -9px;
    width: 194px;
    height: 15px;
    z-index: 36;
  }
  

  

  .dropdown-content-div {
    border: 2px red;
    display: contents;
    clip-path: polygon(0% 0, 100% 0, 100% 83%, 85% 100%, 0 100%, 0% 50%);
    .right-corner-dropdown {
      position: absolute;
      background-color: var(--primary);
      height: 28px;
      width: 1px;
      bottom: 7px;
      right: 16px;
      z-index: 1000;
      transform: rotate(47deg);
    }
  }
`;
