import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { IconContext } from 'react-icons/lib'
import { FaAngleDown } from "react-icons/fa"
import { setLanguage } from '../../../../redux/action/setLanguageAction'
import List from './List'
import { FiX } from 'react-icons/fi'
import { RiSearchLine } from 'react-icons/ri'


const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px 12px;
    font-size: 0.8rem;
    font-weight: bold;
`

const DropdownWrapper = styled.div`
    
`

const Button = styled.a`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    right:0;
    width: ${props => props.show ? '300' : '100'}px;
    transition: width 0.2s ease-in-out;
    height: 36px;
    border: 1px solid #22262E;
    border-radius: 4px;
    padding: 10px 5px;
    line-height: 1em;
`

const LeftSpan = styled.span`
    display: flex;
    align-items: center;
    font-size: .8rem;
    height: 100%;

    svg {
        width: 1.5em;
        height: 1.5em;
    }

    span {
        align-self: center;
    }
`

const RightSpan = styled.span`
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1.5em;
    font-weight: bold;
`

const StyledCaret = styled(FaAngleDown)`
    font-size: 1em;
`


const DropdownContent = styled.div`
    position: absolute;
    right: 12px;
    width: 100%;
    background-color: var(--color-bg-header);
    border: 1px solid #22262E;
    border-radius: 2px;
    border-top-right-radius: 0px;
    overflow-x: hidden;
    width: 300px;
    z-index: 10;
    opacity: ${props => props.show ? '1' : '0'};
    visibility: ${props => (props.show ? "visible" : "hidden")};
    -webkit-transition: height 0.05s linear;
    transition: height 0.05s linear;
    transition: width: 0.2s ease-in-out;
`

const SearchBar = styled.input`
    width: 100%;
    height: 40px;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid #22262E;
    border-left: none;
    background: var(--color-bg-header);
    outline: none;
    padding: 5px 30px 5px 10px;
    color: #fff;
    
    &:focus {
        outline:none;
    }
`

const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
`

const SearchIcon = styled(RiSearchLine)`
    position: absolute;
    right: 10px;
`

const LanguageScroll = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar {
        
      }
      
      &::-webkit-scrollbar {
        width: 10px;
      }
      
      &::-webkit-scrollbar-track {
        background-color: green;
        width: 10px;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: #FFF;
        border-radius: 5px;
      }
`


  


const LocalizationDropdown = ({icon, border, hasCaret, onClickWidthTransition}) => {

    // props
    // border: true / false
    // icon: React-Icon
    // onClickWidthTransition: true / false
    // hasCaret: true / false
    // caretStyle: RxCaretDown


    const dropdown = useRef()
    const [inputText, setInputText] = useState('')
    const [showDropdown, setShowDropdown] = useState(false);
    const language = useSelector(state => state.LANGUAGE.Selected)
    const dispatch = useDispatch()

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])

    const setSelectedLang = (lang) => {
        localStorage.setItem("selectedLanguage", JSON.stringify(lang));
        dispatch(setLanguage(lang));
        setShowDropdown(false);
      };

      let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
      };

    const handleOutsideClick = (e) => {
        if(dropdown.current && !dropdown.current.contains(e.target)){
            setShowDropdown(false)
        }
    }

    const toggleDropdown = (e) => {
        e.preventDefault()
        setShowDropdown(!showDropdown)
    }
    return (
        <Wrapper ref={dropdown}>
            <DropdownWrapper>
                <Button onClick={toggleDropdown} show={showDropdown}>
                    <LeftSpan>
                        <IconContext.Provider value={{color: '#68717E', style: {size: '1.5em', marginRight: "5px" } }}>
                            { icon && icon }
                        </IconContext.Provider>
                        {language.langCode?.toUpperCase()}
                    </LeftSpan>
                    <RightSpan>
                        {hasCaret && <StyledCaret />}
                    </RightSpan>
                </Button>
                <DropdownContent className={showDropdown && 'dropdown_menu-2'}  show={showDropdown}>
                    <SearchWrapper>
                        <SearchBar placeholder="Search for language" onChange={inputHandler} value={inputText} />
                        <IconContext.Provider value={{color: '#68717E', style: {size: '1.5em', marginRight: "5px" } }}>
                            <SearchIcon />
                        </IconContext.Provider>
                        
                    </SearchWrapper>
                    <LanguageScroll className='scroll-gradient'>
                        <List  input={inputText} selectLanguage={setSelectedLang} />    
                    </LanguageScroll>
                </DropdownContent>
            </DropdownWrapper>
        </Wrapper>
    )
}

export default LocalizationDropdown